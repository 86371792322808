import { useAuth0 } from '@auth0/auth0-vue'
import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'

let permissions = ref([])
let organisation = ref('')

export default () => {
  const auth = useAuth0()
  const useApi = apiHandler()

  async function getUserData() {
    const user = await useApi.requestV2('get', 'v1/users/me')
    return user.data
  }

  function parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  function refreshPermissions() {
    return new Promise((resolve, reject) => {
      auth
        .getAccessTokenSilently()
        .then((accessToken) => {
          const parsedJWT = parseJwt(accessToken)
          const tokenPermissions = parsedJWT.permissions

          getUserData().then((data) => {
            const activeOrg = data.active_organization

            organisation.value = activeOrg.name
            if (activeOrg.proces_tool === true) {
              tokenPermissions.push('organisation:proces')
            }

            permissions.value = tokenPermissions

            resolve(true) // Resolve after all async actions have completed
          })
        })
        .catch((error) => {
          console.error('Error refreshing permissions:', error)
          reject(error) // Reject the promise on error
        })
    })
  }

  function canSee(permission) {
    return permissions.value.includes(permission) ? true : false
  }

  return {
    canSee,
    refreshPermissions,
    permissions,
    organisation,
  }
}
