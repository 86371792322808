<template>
  <div v-if="showApp">
    <component :is="layout">
      <router-view />
    </component>

    <base-calculator />

    <!-- toasts -->
    <base-toast></base-toast>

    <!-- modals -->
    <baseModal
      v-if="tooltipHandler.tooltipVisible.value"
      @close="tooltipHandler.hideTooltip()"
      :title="tooltipHandler.getTitle()"
      size="l"
    >
      <p class="text-slate-500">{{ tooltipHandler.getContent() }}</p>
    </baseModal>
  </div>
</template>

<script>
import navigationBar from '@/components/navigationBar.vue'
import LayoutFull from '@/layouts/LayoutFull.vue'
import LayoutHorizontal from '@/layouts/LayoutHorizontal.vue'
import LayoutHorizontalSideBar from '@/layouts/LayoutHorizontalSideBar.vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import useTooltipHandler from '@/use/tooltipHandler'
import router from '@/router'

export default {
  setup() {
    const route = useRoute()
    const useContentGuard = contentGuardHandler()
    const tooltipHandler = useTooltipHandler()
    const showApp = ref(false)

    const layoutSelector = {
      full: 'layout-full',
      horizontal: 'layout-horizontal',
      horizontal_sidebar: 'layout-horizontal-side-bar',
    }

    const layout = computed(() => {
      return layoutSelector[route.meta.layout]
    })

    onMounted(() => {
      useContentGuard.refreshPermissions().then(() => {
        showApp.value = true
        if (useContentGuard.permissions.value.includes('organisation:proces')) {
          router.push({ name: 'addresses' })
        } else {
          router.push({ name: 'surveys' })
        }
      })
    })

    return {
      layout,
      tooltipHandler,
      showApp,
    }
  },
  components: {
    LayoutHorizontal,
    LayoutHorizontalSideBar,
    LayoutFull,
    navigationBar,
  },
}
</script>
