import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import { ref, computed } from 'vue'

const address_name = ref('')

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const address = ref({})
  const address_geometry = ref({})
  const label = ref({})

  const finishedSteps = computed(() => address.value.address_project[0].config.steps_completed)

  const currentStep = computed(() => {
    if (address.value.projects[0].config.stappen.length === finishedSteps.value.length) {
      return {}
    }
    let step =
      address.value.projects[0].config.stappen.find((step) => !finishedSteps.value.includes(step.step_id)) ||
      address.value.projects[0].config.stappen[0]
    return step
  })

  const x = computed(() => {
    // 564112
    if ('centroide_rd' in address.value) {
      const coordinaat = parseCoordinatesRD(address.value.centroide_rd).lng
      return coordinaat
    }
    return '0'
  })

  const y = computed(() => {
    if ('centroide_rd' in address.value) {
      const coordinaat = parseCoordinatesRD(address.value.centroide_rd).lat
      return coordinaat
    }
    return '0'
  })

  const addressDetailFields = ref([
    { label: 'Address', key: 'address', type: 'string' },
    { label: 'Zip', key: 'zip', type: 'string' },
    { label: 'Place', key: 'place', type: 'string' },
    { label: 'Wijk', key: 'wijknaam', type: 'string' },
    { label: 'Buurt', key: 'buurtnaam', type: 'string' },
    { label: 'Waterschap', key: 'waterschapsnaam', type: 'string' },
    { label: 'BAG nummer-id', key: 'nummeraanduiding_id', type: 'string' },
    { label: 'BAG addresseerbaarobject-id', key: 'adresseerbaarobject_id', type: 'string' },
    {
      label: 'Omgevingsloket',
      key: 'action',
      component: 'listLink',
      componentProps: {
        lat: x,
        lng: y,
      },
    },
  ])

  async function getDetails(payload) {
    loadHandler.setLoadingState('get_address_details', true)
    try {
      const id = payload['id']
      const result = await useApi.requestV2('get', `v1/addresses/${id}`)

      const responseData = result.data
      responseData.config.bagId = responseData.config.id
      delete responseData.config.id
      address.value = { ...responseData, ...responseData.config }
      address_name.value = address.value.address
      address_geometry.value = parseCoordinates(responseData.config.centroide_ll)

      const aoId = responseData.config['adresseerbaarobject_id']
      if (aoId) {
        await getLabel({ bagverblijfsobjectid: aoId })
      }
      return address.value
    } finally {
      loadHandler.setLoadingState('get_address_details', false)
    }
  }

  function parseCoordinates(coordinateString) {
    const [lng, lat] = coordinateString.replace('POINT(', '').replace(')', '').split(' ').map(parseFloat)
    return { lat, lng }
  }
  function parseCoordinatesRD(coordinateString) {
    const [lng, lat] = coordinateString.replace('POINT(', '').replace(')', '').split(' ')
    return { lat, lng }
  }

  async function getLabel(payload) {
    loadHandler.setLoadingState('get_label', true)
    try {
      const response = await useApi.requestV2('get', `v1/labels/${payload.bagverblijfsobjectid}`)
      const response_data = response.data
      label.value = response_data
      loadHandler.setLoadingState('get_label', false)
    } finally {
      loadHandler.setLoadingState('get_label', false)
    }
  }

  return {
    loadHandler,
    getDetails,
    addressDetailFields,
    address,
    address_geometry,
    label,
    x,
    finishedSteps,
    currentStep,
    // finishStep,
    // unfinishStep,
    address_name,
  }
}
