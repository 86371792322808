<template>
  <span>
    <!-- top bar -->
    <div class="flex flex-row justify-between">
      <!-- add button / export button / select all-->
      <div class="flex items-center">
        <baseButton
          class="px-4 mr-2 text-2xl py-1 my-2"
          size="large"
          v-if="addButton"
          @action="onAdd()"
          :disabled="loading"
          >+</baseButton
        >
        <baseButton class="px-4 my-2 mr-2" v-if="exportButton" @action="onExport()" :loading="exporting"
          >Exporteren</baseButton
        >
      </div>

      <!-- box right -->
      <div class="flex flex-row">
        <!-- selection status -->
        <div class="my-auto mr-4">
          <div
            v-if="selectable && selectAllOption && totalSelected > 0"
            class="flex items-center text-sm text-gray-500"
          >
            <baseButton @action="onMultiEdit" variant="neutral" class="mx-2" v-if="multiEdit">
              <div class="flex flex-row">
                <span class="my-auto text-xs"> Bewerk {{ totalSelected }} items </span>
                <span class="mt-auto ml-2"><featherIcon class="w-4" icon="Edit3Icon" /></span>
              </div>
            </baseButton>
            <baseButtonDropDown
              :handler="useDropDown"
              variant="neutral"
              :displayText="`${totalSelected} rijen geselecteerd`"
            >
              <baseButtonDropDownItem @click="selectAllRecords"
                >Alle {{ records.length }} rijen selecteren</baseButtonDropDownItem
              >
              <baseButtonDropDownItem @click="deselectAll">Alles deselecteren</baseButtonDropDownItem>
            </baseButtonDropDown>
          </div>
        </div>

        <!-- search -->
        <div
          v-if="!(onFirstPage && onLastPage && searchQuery === '')"
          class="relative px-4 py-3 bg-white shadow-sm rounded text-sm text-gray-500 my-2 border"
        >
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>

          <input v-model="searchQuery" class="pl-8 focus:outline-none w-40" placeholder="zoeken" />
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg border">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <!-- Select column -->
            <th v-if="selectable" scope="col" class="px-6 py-3">
              <span>
                <!-- select all checkbox -->
                <baseCheckBox
                  class="text-gray-500"
                  :model-value="allSelectedOnCurrentPage"
                  @update:model-value="toggleSelectAllOnCurrentPage"
              /></span>
            </th>
            <!-- normal header cells -->
            <th scope="col" class="px-3 py-3" v-for="(field, index) in filteredFields" :key="index">
              <div v-if="sortable" class="flex">
                <chevronupdown
                  v-if="index === sortFieldIndex"
                  @click="onSort(field, index)"
                  class="h-5 w-5 min-w-5 min-h-5 my-auto cursor-pointer"
                />
                <chevronupdown
                  v-else
                  @click="onSort(field, index)"
                  class="h-5 w-5 my-auto text-gray-400 cursor-pointer"
                />
                <span class="my-auto mx-1">{{ $translate(field.label) }}</span>
                <featherIcon
                  class="w-4 h-4 ml-1 my-auto text-gray-400 cursor-pointer"
                  icon="FilterIcon"
                  @click="onShowFilter(field)"
                />
                <div
                  v-show="filterToShow === field.key"
                  class="absolute bg-white shadow-md p-2 mt-10 rounded-md max-h-[10rem] overflow-y-auto"
                >
                  <div
                    v-for="(record, index) in Object.entries(differentRecords[field.key])"
                    :key="index"
                    class="flex flex-col gap-2"
                  >
                    <div class="flex flex-row p-1">
                      <baseCheckBox
                        class="text-gray-500"
                        :model-value="record[1].selected.value"
                        @update:model-value="updateDifferentFilterRecords(field, record)"
                      />
                      <span class="text-gray-500"
                        >{{ field.type === 'date' ? $filter.dateFromEpochDay(parseInt(record[0])) : record[0] }} :
                        {{ record[1].count }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="px-3">
                {{ $translate(field.label) }}
              </div>
            </th>

            <!-- actionfield header cell -->
            <th v-if="actionField !== null" scope="col" class="px-6 py-3">{{ actionField }}</th>

            <!-- action header cell -->
            <th v-if="showActions" scope="col" class="px-6 py-3 max-w-[60]">Acties</th>
          </tr>
        </thead>
        <tbody>
          <!-- row looper -->
          <tr
            @click="onClick(record)"
            :class="rowStyle + ' ' + getRowClass(record)"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            v-for="(record, index) in filteredRecordsPaginated"
            :key="index"
          >
            <!-- Select checkbox -->
            <td v-if="selectable" class="px-6 py-4">
              <baseCheckBox
                :model-value="isSelected(record)"
                @update:model-value="(value) => toggleSelect(record, value)"
                @click.stop
              />
            </td>
            <!-- normal cells -->
            <td class="px-6 py-4" v-for="(field, index) in filteredFields" :key="index">
              <span v-if="field.type === 'date'">{{ $filter.dateFromEpochDay(record[field.key]) }}</span>
              <span v-else>{{ record[field.key] }}</span>
            </td>

            <!-- action field -->
            <td v-if="actionField !== null" class="px-6 py-4">
              <baseCheckBox
                :model-value="record[actionValue]"
                @update:model-value="(value) => onAction(record, value)"
              />
            </td>

            <!-- action cells -->
            <td v-if="showActions" class="px-6 py-4 max-w-[60]">
              <span v-if="exportRecordButton">
                <baseButton size="small" @action="onExportRecord(record)" class="mr-2 my-0" :disabled="loading">
                  <featherIcon class="w-4 h-4" icon="DownloadIcon" />
                </baseButton>
              </span>
              <span v-if="duplicateButton">
                <baseButton size="small" @action="onDuplicate(record[keyField])" class="mr-2 my-0" :disabled="loading">
                  <DuplicateIcon class="h-4 w-4" />
                </baseButton>
              </span>
              <span v-if="editButton">
                <baseButton size="small" @action="onEdit(record, index)" class="mr-2 my-0" :disabled="loading">
                  <PencilIcon class="h-4 w-4" />
                </baseButton>
              </span>
              <span v-if="deleteButton">
                <baseButton
                  size="small"
                  @action="onDelete(record[keyField], index)"
                  class="my-0"
                  variant="danger"
                  :disabled="loading"
                >
                  <TrashIcon class="h-4 w-4" />
                </baseButton>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- no data box -->
      <span v-if="showNoRecords && !loading" class="flex justify-center bg-white">
        <span class="py-6 text-center text-xs text-gray-700">Geen gegevens gevonden</span>
      </span>

      <!-- loader -->
      <span v-if="loading" class="flex justify-center bg-white">
        <span class="py-6 text-center text-xs text-gray-700">
          <baseLoader :loading="loading" />
        </span>
      </span>
    </div>

    <!-- pagination -->
    <div v-if="!(onFirstPage && onLastPage)" class="flex flex-row-reverse ...">
      <div
        :class="onLastPage ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'"
        class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 my-2 cursor-pointer"
        @click="movePpage(1)"
      >
        {{ '>' }}
      </div>
      <div class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 my-2 mx-2">{{ pageIndex + 1 }}/{{ lastPage }}</div>
      <div
        v-if="!onFirstPage"
        class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 cursor-pointer my-2"
        @click="movePpage(-1)"
      >
        {{ '<' }}
      </div>
    </div>

    <!-- confirmer -->
    <confimationModal :handler="confirm" />
  </span>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { TrashIcon, PencilIcon, DuplicateIcon } from '@heroicons/vue/outline'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'
import chevronupdown from '@/components/extended/chevronUpDown.vue'
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'

export default {
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    records: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    searchType: {
      type: String,
      default: 'all',
    },
    recordsPerPage: {
      type: Number,
      default: 10,
    },
    addButton: {
      type: Boolean,
      default: false,
    },
    editButton: {
      type: Boolean,
      default: false,
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
    duplicateButton: {
      type: Boolean,
      default: false,
    },
    exportButton: {
      type: Boolean,
      default: false,
    },
    exportRecordButton: {
      type: Boolean,
      default: false,
    },
    keyField: {
      type: String,
      default: 'id',
    },
    clickAble: {
      type: Boolean,
      default: false,
    },
    clickCallback: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectAllOption: {
      type: Boolean,
      default: false,
    },
    exporting: {
      type: Boolean,
      default: false,
    },
    hoverEffect: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    actionField: {
      type: String,
      default: null,
    },
    actionValue: {
      type: String,
      default: '',
    },
    multiEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add', 'edit', 'delete', 'duplicate', 'export', 'export_record', 'selection', 'action', 'multi_edit'],
  setup(props, { emit }) {
    const pageIndex = ref(0)
    const searchQuery = ref('')
    let sortFieldIndex = ref(null)
    let sortField = ref({})
    let sortAsc = ref(true)
    const selected = ref({})
    const useDropDown = baseButtonDropDownHandler()
    const filterToShow = ref('')

    // handlers
    const confirm = confirmationHandler()

    const differentRecords = computed(() => {
      const records = {}
      props.fields.forEach((field) => {
        records[field.key] = {}
      })
      props.records.forEach((record) => {
        props.fields.forEach((field) => {
          if (!records[field.key][record[field.key]]) {
            records[field.key][record[field.key]] = {}
            records[field.key][record[field.key]]['count'] = 1
            records[field.key][record[field.key]]['selected'] = ref(false)
          } else {
            records[field.key][record[field.key]]['count']++
          }
        })
      })
      return records
    })

    const differentFilterRecords = ref({})

    function updateDifferentFilterRecords(column, record) {
      record[1].selected.value = !record[1].selected.value
      if (record[1].selected.value) {
        if (!differentFilterRecords.value[column.key]) {
          differentFilterRecords.value[column.key] = {}
        }
        differentFilterRecords.value[column.key][record[0]] = record[1]
      } else {
        delete differentFilterRecords.value[column.key][record[0]]
        if (Object.keys(differentFilterRecords.value[column.key]).length === 0) {
          delete differentFilterRecords.value[column.key]
        }
      }
    }

    // fields filter
    const filteredFields = computed(() => {
      return props.fields.filter((field) => {
        if (field.table === false) {
          return false
        }
        return true
      })
    })

    // filter
    const filteredRecords = computed(() => {
      // check if records need filtering
      let filtered = props.records
      if (Object.keys(differentFilterRecords.value).length !== 0) {
        filtered = props.records.reduce((newArray, record) => {
          let add = false
          for (const [column, values] of Object.entries(differentFilterRecords.value)) {
            for (const value of Object.keys(values)) {
              if (String(record[column]) === String(value)) {
                add = true
              }
            }
          }
          if (add) {
            newArray.push(record)
          }
          return newArray
        }, [])
      }

      if (searchQuery.value) {
        const searchQueryLower = searchQuery.value.toLowerCase()
        const queryResult = filterByValue(filtered, searchQueryLower)
        return queryResult
      } else {
        return filtered
      }
    })

    // sort
    const sortedRecords = computed(() => {
      // check if records need filtering
      if (sortFieldIndex.value || sortFieldIndex.value === 0) {
        const key = sortField.value.key
        const type = sortField.value.type
        const copy = JSON.parse(JSON.stringify(filteredRecords.value))
        return sort(copy, key, type)
      } else {
        return JSON.parse(JSON.stringify(filteredRecords.value))
      }
    })

    // pagination
    const filteredRecordsPaginated = computed(() => {
      if (props.pagination) {
        return paginate(sortedRecords.value)
      } else {
        return sortedRecords.value
      }
    })

    // checks if on first page
    const onFirstPage = computed(() => {
      return pageIndex.value === 0
    })

    // checks if on last page
    const onLastPage = computed(() => {
      return filteredRecords.value.length / props.recordsPerPage < pageIndex.value + 1
    })

    // checks what last page's index is
    const lastPage = computed(() => {
      const lastpageNumber = filteredRecords.value.length / props.recordsPerPage
      return lastpageNumber ? Math.ceil(lastpageNumber) : 0
    })

    // New computed properties for page-scoped selection
    const allSelectedOnCurrentPage = computed(() => {
      return (
        filteredRecordsPaginated.value.length > 0 &&
        filteredRecordsPaginated.value.every((record) => isSelected(record))
      )
    })

    const totalSelected = computed(() => {
      return Object.keys(selected.value).length
    })

    // show actions column
    const showActions = computed(() => {
      return props.editButton || props.deleteButton
    })

    // show no records message
    const showNoRecords = computed(() => {
      return props.records.length === 0
    })

    // clickable
    const rowStyle = computed(() => {
      let style = ''
      if (props.clickAble) {
        style += 'cursor-pointer'
      }

      if (props.hoverEffect) {
        style += ' hover:bg-gray-100'
      }

      return style
    })

    // mutates pageindex, used by up and down buttons
    function movePpage(direction) {
      if (direction === -1 && !onFirstPage.value) {
        pageIndex.value += direction
      } else if (direction === 1 && !onLastPage.value) {
        pageIndex.value += direction
      }
    }

    // search filter
    function filterByValue(array, string) {
      return array.filter((record) =>
        props.fields.some((field) => {
          if (field.type === 'string') {
            return record[field.key]?.toLowerCase().includes(string)
          }
        })
      )
    }

    // paginaton filter
    function paginate(source) {
      const startIndex = pageIndex.value * props.recordsPerPage
      const endIndex = startIndex + props.recordsPerPage
      return source.slice(startIndex, endIndex)
    }

    // sort
    function sort(source, key, type) {
      let returnValue = []
      if (type === 'string') {
        returnValue = source.sort((a, b) => (a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1))
      } else {
        returnValue = source.sort((a, b) => (a[key] > b[key] ? 1 : -1))
      }

      if (!sortAsc.value) {
        return returnValue.reverse()
      } else {
        return returnValue
      }
    }

    // add button emitter
    function onAdd(prefill) {
      if (props.addButton) {
        emit('add', prefill)
      }
    }

    // edit button emitter
    function onEdit(record, index) {
      if (props.editButton) {
        emit('edit', record, index)
      }
    }

    function onMultiEdit() {
      emit('multi_edit', Object.values(selected.value))
    }

    // duplicate button emitter
    function onDuplicate(id) {
      if (props.duplicateButton) {
        emit('duplicate', id)
      }
    }

    // export row emitter
    function onExportRecord(payload) {
      if (props.exportRecordButton) {
        emit('export_record', payload)
      }
    }

    // delete button emitter
    async function onDelete(id, index) {
      if (props.deleteButton) {
        const ok = await confirm.open({
          title: 'Object verwijderen?',
          message: 'deleteObject',
        })
        if (ok) {
          emit('delete', id, index)
        }
      }
    }

    // export emitter
    function onExport() {
      if (props.exportButton) {
        emit('export', {
          records: sortedRecords.value,
          fields: filteredFields.value,
        })
      }
    }
    function onAction(record, value) {
      emit('action', record, value)
    }
    // Toggle select all on current page
    function toggleSelectAllOnCurrentPage(value) {
      filteredRecordsPaginated.value.forEach((record) => {
        const identifier = record[props.keyField]
        if (value) {
          selected.value[identifier] = record
        } else {
          delete selected.value[identifier]
        }
      })
      emitSelection()
    }

    // Toggle individual record selection
    function toggleSelect(record, value) {
      const identifier = record[props.keyField]
      if (value) {
        selected.value[identifier] = record
      } else {
        delete selected.value[identifier]
      }
      emitSelection()
    }

    // Check if a record is selected
    function isSelected(record) {
      const identifier = record[props.keyField]
      return identifier in selected.value
    }

    // Emit selection change
    function emitSelection() {
      emit('selection', Object.values(selected.value))
    }

    function getRowClass(record) {
      return isSelected(record) ? '!bg-blue-100' : 'bg-white'
    }

    // row click event
    function onClick(record) {
      if (props.selectable) {
        toggleSelect(record, !isSelected(record))
      } else if (props.clickAble) {
        props.clickCallback(record)
      }
    }

    function onSort(field, index) {
      sortField.value = field
      if (sortFieldIndex.value === index) {
        sortAsc.value = !sortAsc.value
      } else {
        sortFieldIndex.value = index
        sortAsc.value = true
      }
    }

    // Select all records across all pages
    function selectAllRecords() {
      props.records.forEach((record) => {
        const identifier = record[props.keyField]
        selected.value[identifier] = record
      })
      emitSelection()
    }

    // Deselect all rows
    function deselectAll() {
      selected.value = {}
      emitSelection()
    }

    // Watch for changes in filteredRecords
    watch(filteredRecords, () => {
      // Remove any selected items that are no longer in the filtered records
      Object.keys(selected.value).forEach((key) => {
        if (!filteredRecords.value.some((record) => record[props.keyField] === key)) {
          delete selected.value[key]
        }
      })
      emitSelection()
    })

    // Watch for page changes
    watch(pageIndex, () => {
      // No need to clear selections when changing pages
      // The UI will update based on the current page's selection state
    })

    function onShowFilter(field) {
      if (filterToShow.value === field.key) {
        filterToShow.value = ''
      } else {
        filterToShow.value = field.key
      }
    }

    return {
      filteredRecords,
      filteredRecordsPaginated,
      pageIndex,
      movePpage,
      onFirstPage,
      onLastPage,
      lastPage,
      searchQuery,
      onAdd,
      onEdit,
      onDelete,
      showActions,
      showNoRecords,
      filteredFields,
      confirm,
      onClick,
      rowStyle,
      onDuplicate,
      onExport,
      sortFieldIndex,
      onSort,
      sortedRecords,
      onExportRecord,
      selected,
      allSelectedOnCurrentPage,
      toggleSelectAllOnCurrentPage,
      toggleSelect,
      isSelected,
      getRowClass,
      totalSelected,
      useDropDown,
      deselectAll,
      selectAllRecords,
      onAction,
      onMultiEdit,
      differentRecords,
      updateDifferentFilterRecords,
      onShowFilter,
      filterToShow,
    }
  },
  components: {
    confimationModal,
    TrashIcon,
    PencilIcon,
    DuplicateIcon,
    chevronupdown,
  },
}
</script>
