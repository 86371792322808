<template>
  <div class="flex flex-col text-slate-500 gap-2 max-w-xl">
    <baseCard v-for="(appointment, index) in appointments" :key="index">
      <div class="flex flex-row justify-between">
        <div class="flex flex-col justify-between gap-1">
          <p class="text-xl font-bold">{{ appointment.title }}</p>
          <p class="text-sm">
            {{
              new Date(appointment.scheduled_at).toLocaleString(undefined, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })
            }}
          </p>
          <div class="flex flex-row mt-6 gap-2">
            <featherIcon class="w-4 h-4 my-auto" icon="UsersIcon" />
            <p class="text-sm">{{ appointment.attendees.map((attendee) => attendee.first_name).join(', ') }}</p>
          </div>
          <div class="flex flex-row mt-3 gap-2">
            <featherIcon class="w-4 h-4 my-auto" icon="AlignLeftIcon" />
            <p class="text-sm">{{ appointment.description }}</p>
          </div>
          <div class="flex flex-row mt-3 gap-2">
            <featherIcon class="w-4 h-4 my-auto" icon="MapPinIcon" />
            <p class="text-sm">{{ appointment.place }}</p>
          </div>
        </div>
        <div class="flex flex-col justify-between items-end">
          <div class="flex flex-row gap-1">
            <button class="m-1" @click="openEditModal(appointment)">
              <featherIcon class="w-5 h-5" icon="EditIcon" />
            </button>
            <button class="m-1" @click="removeAppointment(appointment)">
              <featherIcon class="w-5 h-5" icon="Trash2Icon" />
            </button>
          </div>
          <div class="flex flex-row gap-1 items-center">
            <div class="text-xs">Exporteer naar</div>
            <baseCalendarButton
              :title="appointment.title"
              :details="appointment.description"
              :location="appointment.place"
              :startDate="new Date(appointment.scheduled_at)"
            />
            <baseCalendarButton
              :title="appointment.title"
              :details="appointment.description"
              :location="appointment.place"
              :startDate="new Date(appointment.scheduled_at)"
              type="outlook"
            />
          </div>
        </div>
      </div>
    </baseCard>
    <baseButton class="mr-auto my-2" @click="showAddModal = true">Voeg Afspraak Toe</baseButton>
  </div>

  <baseModal v-if="showAddModal || showEditModal" @close="closeModal" title="Add appointment">
    <form class="w-full flex flex-col">
      <baseInput v-model="form.title" label="Titel" type="string" id="title" />
      <baseDateTime v-model="form.scheduled_at" />
      <baseTextBox v-model="form.description" label="Omschrijving" id="description" />
      <baseInput v-model="form.place" label="Plaats" type="string" id="place" />

      <baseTableSelect
        v-model="form.attendees"
        label="Aanwezigen"
        :fields="attendeesFields"
        :items="people"
        @add="addAttendee"
        :itemDisplayTemplate="
          (attendees) => attendees.map((attendee) => `${attendee.first_name} ${attendee.last_name}`).join(', ')
        "
      >
        <template v-slot:add-form>
          <form class="w-full flex flex-col">
            <baseInput v-model="attendee.first_name" label="Voornaam" type="string" id="attendeeFirstName" />
            <baseInput v-model="attendee.last_name" label="Achternaam" type="string" id="attendeeLastName" />
            <baseInput v-model="attendee.email" label="E-mail" type="email" id="attendeeEmail" />
            <baseInput v-model="attendee.phone_number" label="Telefoon" type="phone" id="attendeePhoneNumber" />
          </form>
        </template>
      </baseTableSelect>
    </form>
    <template v-slot:buttons>
      <baseButton v-if="showAddModal" @click="addAppointment" class="mr-2">Add</baseButton>
      <baseButton v-if="showEditModal" @click="editAppointment" class="mr-2">Edit</baseButton>
    </template>
  </baseModal>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import apiHandler from '@/use/apiHandler'
import { useRoute } from 'vue-router'

const props = defineProps({
  address: {
    type: String,
    default: '',
  },
})

const appointments = ref([])
const people = ref([])

const form = reactive({
  id: '',
  title: 'Afspraak',
  scheduled_at: new Date().toISOString(),
  description: 'Omschrijving',
  place: props.address,
  attendees: [],
})

const attendee = reactive({
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
})

const showAddModal = ref(false)
const showEditModal = ref(false)

const useApi = apiHandler()
const route = useRoute()

const attendeesFields = [
  { key: 'first_name', type: 'string', label: 'Voornaam' },
  { key: 'last_name', type: 'string', label: 'Achternaam' },
  { key: 'email', type: 'string', label: 'E-mail' },
  { key: 'phone_number', type: 'string', label: 'Telefoon' },
]

onMounted(async () => {
  loadAppointments()
  const response = await useApi.requestV2('get', 'v1/persons')
  people.value = response.data
})

function loadAppointments() {
  const address_id = route.params.objectId
  useApi.requestV2('get', `v1/addresses/${address_id}/appointments`).then((response) => {
    appointments.value = response.data
  })
}

function removeAppointment(appointment) {
  useApi
    .requestV2('delete', `v1/addresses/${route.params.objectId}/appointments/${appointment.id}`)
    .then(() => {
      appointments.value = appointments.value.filter((item) => item.id !== appointment.id)
    })
    .catch((error) => {
      console.log(error)
    })
}

function addAppointment() {
  const payload = { ...form, attendees: form.attendees.map(({ id }) => id) }
  useApi
    .requestV2('post', `v1/addresses/${route.params.objectId}/appointments`, payload)
    .then((response) => {
      appointments.value.push(response.data)
      closeModal()
    })
    .catch((error) => {
      console.log(error)
    })
}

function editAppointment() {
  const payload = { ...form, attendees: form.attendees.map(({ id }) => id) }
  useApi
    .requestV2('patch', `v1/addresses/${route.params.objectId}/appointments/${form.id}`, payload)
    .then((response) => {
      const index = appointments.value.findIndex((appointment) => appointment.id === response.data.id)
      appointments.value[index] = response.data
      closeModal()
    })
    .catch((error) => {
      console.log(error)
    })
}

function openEditModal(appointment) {
  Object.assign(form, appointment)
  showEditModal.value = true
}

function addAttendee(attendeeData) {
  useApi.requestV2('post', 'v1/persons', attendeeData).then((response) => {
    people.value.push(response.data)
  })
}

function closeModal() {
  showAddModal.value = false
  showEditModal.value = false

  Object.assign(form, {
    id: '',
    title: 'Afspraak',
    scheduled_at: new Date().toISOString(),
    description: 'Omschrijving',
    place: props.address,
    attendees: [],
  })
}
</script>
