/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
// import lookupHandler from '@/use/lookupHandler'

export default () => {
  // const useLookup = lookupHandler()

  const soortWoningMapper = {
    0: '',
    1: 'Vrijstaand',
    2: '2/1 kap/hoekwoning',
    3: 'Tussenwoning',
    4: '',
    5: '',
    6: '',
    7: 'Appartement',
    8: 'Appartement',
    9: '',
    10: '',
    11: '',
    12: '2/1 kap/hoekwoning',
    13: 'Vrijstaand',
    14: 'Vrijstaand',
    15: 'Vrijstaand',
    16: 'Vrijstaand',
  }

  const orientatieMapper = {
    0: 'zuid',
    1: 'zuidwest',
    2: 'west',
    3: 'noordwest',
    4: 'noord',
    5: 'noordoost',
    6: 'oost',
    7: 'zuidoost',
  }

  const constructieMapper = {
    0: 'Gevel',
    1: 'Paneel',
    2: 'Raam',
    3: 'Deur',
    4: 'Dak',
    5: 'Dak', // hellend
    6: 'Dak', // plat
    7: 'Vloer',
  }

  const grenstAanMapper = {
    0: 'onder',
    1: '',
    2: 'grond',
    3: 'kruipruimte',
    4: 'aangrenzende onverwarmde ruimte',
    5: 'aangrenzende onverwarmde ruimte',
    6: 'aangrenzende onverwarmde ruimte',
    7: 'aangrenzende onverwarmde ruimte',
    8: '',
  }

  const opwekkerLookup = {
    0: 'Gaskachels lokaal',
    1: '',
    2: 'Infraroodpanelen / elektrische radiatoren',
    3: 'Gaskachels lokaal',
    4: {
      1: 'VR gasketel HTV (oud)',
      2: 'HR 100 gasketel HTV (oud)',
      3: 'HR 100 gasketel HTV (oud)',
      4: 'HR 107 gasketel HTV (recent)',
    },
    5: '',
    6: '',
    7: '',
    8: {
      0: {
        0: 'Warmtepomp + grondbuizen 35 graden',
        1: 'Warmtepomp + grondbuizen 35 graden',
        2: 'Warmtepomp + grondbuizen 45 graden',
        3: 'Warmtepomp + grondbuizen 45 graden',
        4: 'Warmtepomp + grondbuizen 55 graden',
        5: 'Warmtepomp + grondbuizen 55 graden',
        6: 'Warmtepomp + grondbuizen 55 graden',
        7: 'Warmtepomp + grondbuizen 55 graden',
        8: 'Warmtepomp + grondbuizen 55 graden',
      },
      1: {
        0: 'Warmtepomp + buitenlucht 35 graden',
        1: 'Warmtepomp + buitenlucht 35 graden',
        2: 'Warmtepomp + buitenlucht 45 graden',
        3: 'Warmtepomp + buitenlucht 45 graden',
        4: 'Warmtepomp + buitenlucht 55 graden',
        5: 'Warmtepomp + buitenlucht 55 graden',
        6: 'Warmtepomp + buitenlucht 55 graden',
        7: 'Warmtepomp + buitenlucht 55 graden',
        8: 'Warmtepomp + buitenlucht 55 graden',
      },
      2: {
        0: 'Warmtepomp + buitenlucht 35 graden',
        1: 'Warmtepomp + buitenlucht 35 graden',
        2: 'Warmtepomp + buitenlucht 45 graden',
        3: 'Warmtepomp + buitenlucht 45 graden',
        4: 'Warmtepomp + buitenlucht 55 graden',
        5: 'Warmtepomp + buitenlucht 55 graden',
        6: 'Warmtepomp + buitenlucht 55 graden',
        7: 'Warmtepomp + buitenlucht 55 graden',
        8: 'Warmtepomp + buitenlucht 55 graden',
      },
    },
    9: '',
    10: '',
    11: '',
    12: {
      0: 'Warmtenet 45⁰C',
      1: 'Warmtenet 45⁰C',
      2: 'Warmtenet 45⁰C',
      3: 'Warmtenet 45⁰C',
      4: 'Warmtenet 55⁰C',
      5: 'Warmtenet 55⁰C',
      6: 'Warmtenet 65⁰C',
      7: 'Warmtenet 65⁰C',
      8: 'Warmtenet 65⁰C',
      9: 'Warmtenet 65⁰C',
      10: 'Warmtenet 65⁰C',
      11: 'Warmtenet 65⁰C',
    },
  }

  const tapwaterOpwekkerLookup = {
    5: 'Boosterwarmtepomp',
    8: 'Elektrische boiler',
    9: 'Elektrische doorstroomverwarmer 11kW',
    6: 'Elektrische doorstroomverwarmer 11kW',
    7: '',
    1: 'Gasboiler',
    0: 'Geiser',
    3: '',
  }

  const tapwaterWarmtepompLookup = {
    0: 'Warmtepomp op ventilatielucht 200l boiler',
    1: 'Warmtepomp op buitenlucht 200l boiler',
    2: 'Warmtepomp op buitenlucht 200l boiler',
  }

  // hier mist type 4
  const tapwaterGasLookup = {
    1: {
      0: 'VR gasketel',
      1: 'VR gasketel',
      2: 'HR 107 gasketel (>15j)',
      3: 'VR gasketel',
      4: 'HR 107 gasketel (recent)',
    },
    2: {
      0: 'VR gasketel',
      1: 'VR gasketel',
      2: 'HR 107 gasketel (>15j)',
      3: 'VR gasketel',
      4: '',
    },
  }

  const opwekkerBinnenSchilLookup = {
    0: 'Nee',
    1: 'Ja',
  }

  const leidingIsolatieLookup = {
    0: 'Geen leidingen buiten de thermische schil',
    1: {
      0: 'Ja, geïsoleerd leidingen',
      1: 'Ja, geïsoleerd leidingen',
      2: 'Ja, ongeïsoleerde leidingen',
      3: 'Ja, ongeïsoleerde leidingen',
      4: 'Ja, ongeïsoleerde leidingen',
    },
  }

  function categorizeBouwjaar(year) {
    const bouwjaarCategorie = [
      { label: '< 1945', min: -Infinity, max: 1944 },
      { label: '1945 - 1970', min: 1945, max: 1970 },
      { label: '1970 - 1980', min: 1971, max: 1980 },
      { label: '1980 - 1990', min: 1981, max: 1990 },
      { label: '1990 - 2000', min: 1991, max: 2000 },
      { label: '2000 - 2010', min: 2001, max: 2010 },
      { label: '> 2010', min: 2011, max: Infinity },
    ]

    const category = bouwjaarCategorie.find((c) => year >= c.min && year <= c.max)
    return category ? category.label : ''
  }

  function getNodeDataNumber(node) {
    const data = node && node.textContent !== null ? Number(node.textContent) : ''
    return data
  }

  function getNodeDataString(node) {
    const data = node && node.textContent !== null ? node.textContent : ''
    return data
  }

  function parseTwo(currentXMLString, newXMLString, basicInfoSource) {
    const currentSituation = parse(currentXMLString, 'current')
    const newSituation = parse(newXMLString, 'new')
    const basicInfo = basicInfoSource === 'current' ? currentSituation : newSituation

    // Merge the results
    const mergedConfig = {
      ...basicInfo.config,
      klimaatzones_huidig: currentSituation.config.klimaatzones_huidig,
      installaties: currentSituation.config.installaties,
      klimaatzones_nieuw: newSituation.config.klimaatzones_nieuw,
      installaties_nieuw: newSituation.config.installaties_nieuw,
    }

    return {
      name: basicInfo.name,
      address: basicInfo.address,
      zip: basicInfo.zip,
      place: basicInfo.place,
      description: basicInfo.description,
      config: mergedConfig,
    }
  }

  function parse(xmlString, importType = 'current') {
    let parser = new DOMParser()
    let xmlDoc = parser.parseFromString(xmlString, 'text/xml')

    const xmlVersie = xmlDoc.getElementsByTagName('LatestXmlUpgrade')[0]?.textContent
    const xmlVersieInt = parseInt(xmlVersie)

    let deelVlakLijstKey = 'DeelvlakLijst'

    if (xmlVersieInt >= 110000045) {
      deelVlakLijstKey = 'DeelvlakList'
    }

    // Algemene gegevens
    const straatnaam = xmlDoc.getElementsByTagName('Straatnaam')[0]?.textContent || ''
    const huisnr = xmlDoc.getElementsByTagName('Huisnr')[0]?.textContent || ''
    const postcode = xmlDoc.getElementsByTagName('Postcode')[0]?.textContent || ''
    const plaatsnaam = xmlDoc.getElementsByTagName('Plaatsnaam')[0]?.textContent || ''

    const buildingcatergory = xmlDoc.getElementsByTagName('BuildingCategory')[0]?.textContent || ''
    const constructionYear = xmlDoc.getElementsByTagName('ConstructionYear')[0]?.textContent || ''

    const soort_woning = buildingcatergory in soortWoningMapper ? soortWoningMapper[buildingcatergory] : ''
    const bouwjaar_woning = categorizeBouwjaar(constructionYear)

    const config_default = {
      soort_woning: {
        soort_woning: soort_woning,
        bouwjaar_woning: bouwjaar_woning,
      },
      installaties: {},
      installaties_nieuw: {},
      klimaatzones_huidig: [],
      klimaatzones_nieuw: [],
      maatregelen: [],
      hypotheek: {},
    }

    const klimaatzone = {
      afwijkende_opwekker: '',
      afwijkende_ventilatie: '',
      luchtdichtheid: '',
      naam: 'Monitor totaal',
      setpoint_vermogensberekening: 20,
      stookgedrag_setpoint: 20,
      ventilatie_mechanisch: '',
      ventilatie_natuurlijk: '',
      ventilatie_wtw: '',
      verbruik_vermindering: '0',
      verbruik_vermindering_reden: '',
      verbruiksoppervlak: '',
      warmteafgifte: [],
      warmteopwekker: '',
      bouwdelen: [],
    }

    // Construct the parsed_file object
    const parsed_file = {
      name: straatnaam,
      address: `${straatnaam} ${huisnr}`,
      zip: postcode,
      place: plaatsnaam,
      description: `${postcode} ${huisnr}, ${plaatsnaam} (import monitor)`,
    }

    // Constructies
    const constructieContainer = xmlDoc.getElementsByTagName('Constructies')[0]
    const constructies = constructieContainer.getElementsByTagName('Constructie')
    const constructiesParsed = parseConstructies(constructies)

    // Hoofdvlakken
    const geometrie = xmlDoc.getElementsByTagName('Geometrie')[0]
    if (geometrie) {
      const hoofdvlakken = geometrie.getElementsByTagName('Hoofdvlak')
      const bouwdelen = parseHoofdvlakken(hoofdvlakken, constructiesParsed, deelVlakLijstKey)
      klimaatzone.bouwdelen = bouwdelen
    }

    // Gebruiksoppervlak
    const resultaten = xmlDoc.getElementsByTagName('Resultaten')[0]
    if (resultaten) {
      const totaalGebruiksOppervlakte = resultaten.getElementsByTagName('Gebruiksoppervlakte')[0]?.textContent || ''
      klimaatzone['verbruiksoppervlak'] = totaalGebruiksOppervlakte
    }

    // Zonne-energie
    const zonneenergiesystemenParent = xmlDoc.getElementsByTagName('ZonneEnergiesystemen')[0]
    if (zonneenergiesystemenParent) {
      const zonneenergiesystemen = zonneenergiesystemenParent.getElementsByTagName('ZonneEnergiesysteem') || ''
      const zonnepanelenParsed = parseZonnepanelen(zonneenergiesystemen)
      config_default['installaties']['zonnepanelen'] = zonnepanelenParsed
      config_default['installaties_nieuw']['zonnepanelen'] = zonnepanelenParsed
    }

    // Zonneboiler
    const zonneboilerAanwezig = xmlDoc.getElementsByTagName('ZonneboilerAanwezig')[0]?.textContent || ''
    if (zonneboilerAanwezig === '1') {
      config_default['installaties']['zonneboiler_voor_bron_warm_tapwater'] = 'Zonneboiler 200l vat'
      config_default['installaties_nieuw']['zonneboiler_voor_bron_warm_tapwater'] = 'Zonneboiler 200l vat'
    }

    // Installaties
    const installaties = xmlDoc.getElementsByTagName('Installaties')[0]

    // Verwarming
    let verwarming_opwekker = ''
    let opwekking_binnen_thermische_schil = ''
    if (installaties) {
      const verwarmingsinstallaties = installaties.getElementsByTagName('Verwarming')[0]
      if (verwarmingsinstallaties) {
        const opwekkers = verwarmingsinstallaties.getElementsByTagName('VerwarmingOpwekker')
        const eerste_opwekker = opwekkers[0]

        let opwekker_binnen_schil_tag = eerste_opwekker.getElementsByTagName('OpwekkerBinnenThermischeSchil')[0]
        if (!opwekker_binnen_schil_tag) {
          opwekker_binnen_schil_tag = eerste_opwekker.getElementsByTagName('OpwekkerBinnenThermischeZone')[0]
        }

        if (opwekker_binnen_schil_tag) {
          const opwekker_binnen_schil = getNodeDataNumber(opwekker_binnen_schil_tag)

          opwekking_binnen_thermische_schil =
            opwekker_binnen_schil in opwekkerBinnenSchilLookup ? opwekkerBinnenSchilLookup[opwekker_binnen_schil] : ''

          verwarming_opwekker = parseVerwarming(opwekkers)
        }
      }
    }

    // Ventilatie
    let ventilatieinfo = { ventilatie_natuurlijk: '', ventilatie_mechanisch: '0', ventilatie_wtw: '0' }
    if (installaties) {
      const ventilatieinstallaties = installaties.getElementsByTagName('VentilatiesysteemList')[0]
      if (ventilatieinstallaties) {
        const ventilators = ventilatieinstallaties.getElementsByTagName('Ventilatiesysteem')
        ventilatieinfo = parseVentilators(ventilators)
      }
    }

    // Tapwater
    let tapwateropwekker = ''
    if (installaties) {
      const tapwaterinstallaties = installaties.getElementsByTagName('Tapwater')[0]
      if (tapwaterinstallaties) {
        const tapwatersystemen = tapwaterinstallaties.getElementsByTagName('Tapwatersysteem')
        const tapwateropwekkers = tapwaterinstallaties.getElementsByTagName('TapwaterOpwekker')
        tapwateropwekker = parseTapwater(tapwatersystemen, tapwateropwekkers)
      }
    }

    // leidingen
    const onverwarmd_leidingen_door_ruimte =
      xmlDoc.getElementsByTagName('OnverwarmdLeidingenDoorRuimte')[0]?.textContent || ''

    const leidingen_onverwarmd_ruimte = xmlDoc.getElementsByTagName('VerwarmingLeidingenOnverwarmdeRuimte')[0]

    let leidingen_geisoleerd = ''
    if (leidingen_onverwarmd_ruimte) {
      leidingen_geisoleerd =
        leidingen_onverwarmd_ruimte.getElementsByTagName('LeidingenGeisoleerd')[0]?.textContent || ''
    }

    let leidingen_buiten_schil = ''
    if (onverwarmd_leidingen_door_ruimte === '0') {
      leidingen_buiten_schil = leidingIsolatieLookup[onverwarmd_leidingen_door_ruimte]
    } else if (
      onverwarmd_leidingen_door_ruimte === '1' &&
      leidingen_geisoleerd in leidingIsolatieLookup[onverwarmd_leidingen_door_ruimte]
    ) {
      leidingen_buiten_schil = leidingIsolatieLookup[onverwarmd_leidingen_door_ruimte][leidingen_geisoleerd]
    }

    // Populate the appropriate fields based on importType
    if (importType === 'current' || importType === 'both') {
      config_default['klimaatzones_huidig'].push(klimaatzone)
      config_default['installaties'] = {
        zonnepanelen: config_default['installaties']['zonnepanelen'],
        zonneboiler_voor_bron_warm_tapwater: config_default['installaties']['zonneboiler_voor_bron_warm_tapwater'],
        warmteopwekker: verwarming_opwekker,
        opwekking_binnen_thermische_schil: opwekking_binnen_thermische_schil,
        ventilatie_natuurlijk: ventilatieinfo.ventilatie_natuurlijk,
        ventilatie_mechanisch: ventilatieinfo.ventilatie_mechanisch,
        ventilatie_wtw: ventilatieinfo.ventilatie_wtw,
        bron_warm_tapwater: tapwateropwekker,
        leidingen_buiten_schil: leidingen_buiten_schil,
      }
    }

    if (importType === 'new' || importType === 'both') {
      config_default['klimaatzones_nieuw'].push(klimaatzone)
      config_default['installaties_nieuw'] = {
        zonnepanelen: config_default['installaties_nieuw']['zonnepanelen'],
        zonneboiler_voor_bron_warm_tapwater:
          config_default['installaties_nieuw']['zonneboiler_voor_bron_warm_tapwater'],
        warmteopwekker: verwarming_opwekker,
        opwekking_binnen_thermische_schil: opwekking_binnen_thermische_schil,
        ventilatie_natuurlijk: ventilatieinfo.ventilatie_natuurlijk,
        ventilatie_mechanisch: ventilatieinfo.ventilatie_mechanisch,
        ventilatie_wtw: ventilatieinfo.ventilatie_wtw,
        bron_warm_tapwater: tapwateropwekker,
        leidingen_buiten_schil: leidingen_buiten_schil,
      }
    }

    parsed_file['config'] = config_default
    return parsed_file
  }
  function parseConstructies(constructies) {
    const data = {}
    Array.from(constructies).forEach((constructie) => {
      const guid = constructie.getElementsByTagName('Guid')[0]?.textContent || ''
      const type = constructie.getElementsByTagName('ConstructieType')[0]?.textContent || ''
      data[guid] = type
    })
    return data
  }

  function parseZonnepanelen(zonneenergiesystemen) {
    const zonnepanelen = []
    Array.from(zonneenergiesystemen).forEach((systeem) => {
      const description = getNodeDataString(systeem.getElementsByTagName('Omschrijving')[0])
      const productie = getNodeDataNumber(systeem.getElementsByTagName('Spv')[0])

      if (productie) {
        zonnepanelen.push({
          aantal: '',
          beschaduwing: '',
          description: description,
          hellingshoek: '',
          omvormer: '',
          orientatie: '',
          productie: productie,
          uitgebreid: false,
          vermogen: '',
        })
      }
    })

    return zonnepanelen
  }

  function getGlassType(u, zta) {
    if (u >= 0.5 && u <= 1.4) {
      if (zta > 0.5) return 'Drievoudige beglazing'
      if (zta <= 0.5) return 'Drievoudige beglazing zonwerend'
    } else if (u > 1.4 && u <= 1.8) {
      if (zta > 0.5) return 'HR++'
      if (zta <= 0.5) return 'HR++ zonwerend'
    } else if (u > 1.8 && u <= 2) {
      return 'HR+ glas'
    } else if (u > 2 && u <= 2.3) {
      return 'HR glas'
    } else if (u > 2.3 && u <= 2.9) {
      return 'Dubbel glas'
    } else if (u > 2.9 && u <= 5.1) {
      return 'Enkel glas'
    }
    return 'Unknown glass type' // Default case, if none of the conditions are met
  }

  function parseHoofdvlakken(hoofdvlakken, constructies, deelVlakLijstKey) {
    const bouwdelen = []
    Array.from(hoofdvlakken).forEach((hoofdvlak) => {
      const bouwdeelIsInactief = hoofdvlak.getElementsByTagName('BouwdeelIsInactief')[0]?.textContent || ''

      if (bouwdeelIsInactief === '1') {
        return // Continue to the next hoofdvlak in the forEach loop
      }

      const clonedHoofdvlak = hoofdvlak.cloneNode(true)
      const oppervlake = hoofdvlak.getElementsByTagName('Oppervlakte')[0]?.textContent || ''
      let orientatie = hoofdvlak.getElementsByTagName('Orientatie')[0]?.textContent || ''
      const constructieId = hoofdvlak.getElementsByTagName('Constructie')[0]?.textContent || ''
      const naam = clonedHoofdvlak.getElementsByTagName('Naam')[0]?.textContent || ''
      const rc = hoofdvlak.querySelector(':scope > Rc')?.textContent || ''
      const deelVlakLijst = hoofdvlak.getElementsByTagName(deelVlakLijstKey)[0]
      const deelvlakken = deelVlakLijst.getElementsByTagName('Deelvlak')
      const grenstAan = hoofdvlak.getElementsByTagName('GrenstAan')[0]?.textContent || ''

      const type = constructies[constructieId]
      const hoofdvlakType = constructieMapper[type]

      let orientatieNieuw = ''
      console.log(typeof type)

      if (type === '6') {
        orientatie = 'boven'
      } else if (type === '7') {
        // anders kijken waar het aan grenst
        orientatie = grenstAan in grenstAanMapper ? grenstAanMapper[grenstAan] : ''
      } else {
        // als wel orientatie, map orientatie
        const numericOrientatie = Number(orientatie)
        orientatie = numericOrientatie in orientatieMapper ? orientatieMapper[numericOrientatie] : ''
      }

      const hoofdvlakData = {
        deelvlak: [],
        isolatieniveau: rc,
        omschrijving: naam,
        oppervlakte: oppervlake,
        orientatie: orientatie,
        type: hoofdvlakType,
      }

      if (deelvlakken) {
        Array.from(deelvlakken).forEach((deelvlak) => {
          const naam = deelvlak.getElementsByTagName('Naam')[0]?.textContent || ''
          const oppervlakte = deelvlak.getElementsByTagName('Oppervlakte')[0]?.textContent || ''
          const BelemmeringPercentage = deelvlak.getElementsByTagName('BelemmeringPercentage')[0]?.textContent || ''
          const u = deelvlak.getElementsByTagName('U')[0]?.textContent || ''
          const zta = deelvlak.getElementsByTagName('G')[0]?.textContent || ''

          const constructieId = deelvlak.getElementsByTagName('Constructie')[0]?.textContent || ''
          const type = constructies[constructieId]
          const deeldvlakType = constructieMapper[type]

          let soort = ''

          // Raam
          if (deeldvlakType === 'Raam') {
            soort = getGlassType(u, zta)
            // Deur
          } else if (deeldvlakType === 'Deur') {
            if (u <= 2) {
              soort = 'Geisoleerde deur'
            } else {
              soort = 'Ongeisoleerde deur'
            }
            // paneel
          } else if (deeldvlakType === 'Paneel') {
            if (u <= 1.4) {
              soort = 'Paneel isolatie'
            } else {
              soort = 'Paneel geen isolatie'
            }
          }

          const deelvlakData = {
            belemmering: 0,
            oppervlakte: oppervlakte,
            soort: soort,
          }

          hoofdvlakData.deelvlak.push(deelvlakData)
        })
      }

      bouwdelen.push(hoofdvlakData)
    })
    return bouwdelen
  }

  function parseVerwarming(opwekkers) {
    let opwekker = ''
    if (opwekkers) {
      const eerste_opwekker = opwekkers[0]
      const type_opwekker = getNodeDataNumber(eerste_opwekker.getElementsByTagName('TypeOpwekker')[0])
      const subtype_opwekker = getNodeDataNumber(eerste_opwekker.getElementsByTagName('SubType')[0])
      const aanvoertemperatuur = getNodeDataNumber(eerste_opwekker.getElementsByTagName('WaterAanvoertemperatuur')[0])

      if (![8, 12, 4].includes(type_opwekker)) {
        opwekker = opwekkerLookup[type_opwekker]
      } else if (type_opwekker === 4) {
        opwekker = opwekkerLookup[type_opwekker][subtype_opwekker]
      } else if (type_opwekker === 12) {
        opwekker = opwekkerLookup[type_opwekker][aanvoertemperatuur]
      } else if (type_opwekker === 8) {
        opwekker = opwekkerLookup[type_opwekker][subtype_opwekker][aanvoertemperatuur]
      }
    }

    return opwekker
  }

  function parseVentilators(ventilators) {
    // ventilatiesysteem
    let ventilatie_natuurlijk = ''
    let ventilatie_wtw = '0'
    let ventilatie_mechanisch = '0'

    if (ventilators) {
      const eerste_ventilator = ventilators[0]
      const subsysteem = getNodeDataNumber(eerste_ventilator.getElementsByTagName('Subsysteem')[0])
      const typewtw = getNodeDataNumber(eerste_ventilator.getElementsByTagName('TypeWtw')[0])

      if (subsysteem === 0) {
        ventilatie_natuurlijk = 'Te openen ramen'
      } else if ([1, 2, 3, 4, 5].includes(subsysteem)) {
        ventilatie_natuurlijk = 'Ventilatieroosters'
      } else if ([6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].includes(subsysteem)) {
        ventilatie_natuurlijk = 'Geen (alleen mechanisch)'
        ventilatie_mechanisch = '150'
      } else if ([26, 27, 28, 29, 30, 31, 32, 33].includes(subsysteem)) {
        if (typewtw === 0) {
          ventilatie_natuurlijk = 'Geen (alleen mechanisch)'
          ventilatie_mechanisch = '150'
        } else if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(typewtw)) {
          ventilatie_natuurlijk = 'Geen (alleen mechanisch)'
          ventilatie_wtw = '150'
        }
      }
    }

    return {
      ventilatie_natuurlijk,
      ventilatie_wtw,
      ventilatie_mechanisch,
    }
  }

  function parseTapwater(tapwatersystemen, tapwateropwekkers) {
    // tapwater
    let tapwateropwekker = ''
    if (tapwateropwekkers && tapwatersystemen) {
      const eerste_tapwateropwekker = tapwateropwekkers[0]
      const eerste_tapwatersysteem = tapwatersystemen[0]

      const type_installatie = getNodeDataNumber(eerste_tapwatersysteem.getElementsByTagName('TypeInstallatie')[0])
      const type_toestel = getNodeDataNumber(eerste_tapwateropwekker.getElementsByTagName('TypeToestel')[0])
      const bron_warmtepomp = getNodeDataNumber(eerste_tapwateropwekker.getElementsByTagName('BronWarmtepomp')[0])
      const gaskeur = getNodeDataNumber(eerste_tapwateropwekker.getElementsByTagName('Gaskeur')[0])

      if ([2, 3].includes(type_installatie)) {
        tapwateropwekker = 'Warmtenet'
      } else if (type_installatie === 0) {
        if (![4, 2, 1].includes(type_toestel)) {
          tapwateropwekker = tapwaterOpwekkerLookup[type_toestel]
        } else if (type_toestel === 4) {
          tapwateropwekker = tapwaterWarmtepompLookup[bron_warmtepomp]
        } else if ([2, 1].includes(type_toestel)) {
          tapwateropwekker = tapwaterGasLookup[type_toestel][gaskeur]
        }
      }
    }

    return tapwateropwekker
  }

  return {
    parse,
    parseTwo,
  }
}
