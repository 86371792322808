<template>
  <baseTable
    :fields="userFields"
    :records="users"
    :records-per-page="25"
    :add-button="true"
    @add="showAddModal = true"
  />

  <baseModal v-if="showAddModal" size="m" @close="closeModal" title="Add user">
    <form class="w-full flex flex-col">
      <baseInput v-model="name" label="Name" type="string" id="name" />
      <baseInput v-model="email" label="Email" type="email" id="email" />
      <baseInput v-model="password" label="Password" :password="true" type="string" id="password" />
    </form>
    <template v-slot:buttons>
      <baseButton @action="addUser" class="mr-2">Add</baseButton>
    </template>
  </baseModal>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import apiHandler from '@/use/apiHandler'
import toastHandler from '@/use/toastHandler'

const useApi = apiHandler()
const userFields = ref([
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email' },
  { key: 'role', label: 'Role' },
])

const useToast = toastHandler()
const showAddModal = ref(false)
const users = ref([])

const name = ref('')
const email = ref('')
const password = ref('')

onMounted(() => {
  getUsers()
})

async function getUsers() {
  const { data } = await useApi.requestV2('get', 'admin/users')
  users.value = data
}

async function closeModal() {
  showAddModal.value = false
}

async function addUser() {
  try {
    const response = await useApi.requestV2('post', 'admin/users', {
      name: name.value,
      email: email.value,
      password: password.value,
    })
    users.value.push(response.data)
  } catch (error) {
    useToast.addToast(`Error adding user:\n${error.response.data.detail.replaceAll('. ', '.\n')}`, 'danger', 10000)
    console.error('Error adding user', error)
  }
  showAddModal.value = false
}
</script>
