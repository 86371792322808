<template>
  <div>
    <div
      v-for="(item, index) in items"
      :key="item.id"
      @click="() => selectItem(item)"
      :class="[
        'flex items-center py-2 px-4 hover:bg-gray-200 transition-colors duration-150 ease-in-out cursor-pointer border rounded shadow-md shadow-gray-200',
        hasGutters && index !== items.length - 1 ? 'mb-2' : '',
        selectedItem === item ? 'bg-sky-100' : 'bg-white',
      ]"
      draggable="true"
      @dragstart="onDragStart($event, item)"
      @dragover.prevent
      @drop="onDrop($event, item)"
    >
      <slot :item="item"></slot>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    hasGutters: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const selectedItem = ref(null)
    const previousItem = ref(null)

    // Watch for changes on selectedItem to emit the 'changed' event
    watch(selectedItem, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        emit('changed', { source: oldVal, target: newVal })
        previousItem.value = newVal
      }
    })

    const selectItem = (item) => {
      selectedItem.value = item
    }

    const onDragStart = (event, item) => {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('itemID', item.id)
    }

    const onDrop = (event, targetItem) => {
      const draggedItemId = event.dataTransfer.getData('itemID')
      const draggedIndex = props.items.findIndex((item) => item.id === draggedItemId)
      const targetIndex = props.items.findIndex((item) => item.id === targetItem.id)

      if (draggedIndex !== -1 && targetIndex !== -1 && draggedIndex !== targetIndex) {
        const movedItems = [...props.items]
        const [draggedItem] = movedItems.splice(draggedIndex, 1)
        movedItems.splice(targetIndex, 0, draggedItem)
        // Emit an event or call a method to update the items array in the parent component
        emit('update:items', movedItems)
      }
    }

    return {
      selectedItem,
      selectItem,
      onDragStart,
      onDrop,
    }
  },
}
</script>
