<template>
  <label class="flex flex-wrap w-full md:items-center cursor-pointer">
    <div class="md:w-1/3">
      <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-2 py-2 text-sm">
        {{ label }}
      </label>
    </div>
    <div class="md:w-2/3">
      <div class="relative">
        <input type="checkbox" :checked="modelValue" @change="updateValue" class="sr-only" :disabled="disabled" />
        <div
          class="w-5 h-5 border-2 rounded transition-colors flex flex-row"
          :class="{
            'border-sky-600 bg-sky-700': modelValue,
            'border-gray-300 bg-white': !modelValue,
            'opacity-50 cursor-not-allowed': disabled,
          }"
        >
          <svg
            v-if="modelValue"
            class="w-3 h-3 text-white m-auto"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const updateValue = (event) => {
      emit('update:modelValue', event.target.checked)
    }

    return {
      updateValue,
    }
  },
}
</script>

<style scoped>
/* Add any additional custom styles here */
</style>
