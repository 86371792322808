<template>
  <div class="flex flex-row h-screen w-full">
    <!-- sidebar -->
    <div
      :class="`fixed h-screen overflow-hidden transition-all duration-300 ease-in-out border-r border-gray-300 ${
        useSidebar.show.value ? 'w-52' : 'w-14'
      }`"
    >
      <sidebar :collapsed="!useSidebar.show.value" @toggle="useSidebar.toggle" />
    </div>

    <div
      class="w-full"
      :class="`flex flex-col flex-grow transition-all duration-300 ease-in-out overflow-auto ${
        useSidebar.show.value ? 'ml-52' : 'ml-14'
      }`"
    >
      <!-- top bar -->
      <div class="p-4 w-full text-gray-500 border-b-gray-300 border-b flex flex-row gap-4 select-none">
        <div class="flex flex-row cursor-pointer" @click="openOrgSwitcher">
          <featherIcon class="w-6 h-6" icon="UsersIcon" />
          <featherIcon class="w-4 h-4 self-end" icon="ChevronDownIcon" />
        </div>
        <div>{{ useContentGuard.organisation }}</div>
        <div v-if="project_name">/</div>
        <div v-if="project_name">{{ project_name }}</div>
        <div v-if="address_name">/</div>
        <div v-if="address_name">{{ address_name }}</div>
      </div>
      <div>
        <div
          v-if="showOrgSwitcher"
          ref="selectRef"
          class="absolute z-10 m-1 bg-white shadow-md rounded border text-gray-500"
        >
          <div
            v-for="org in organisations"
            :key="org.value"
            @click="changeOrganisation(org.value)"
            class="cursor-pointer hover:bg-gray-200 py-2 px-4"
          >
            {{ org.label }}
          </div>
        </div>
      </div>
      <!-- content -->
      <div :class="`grow ${backgroundColor} overflow-auto`">
        <div class="px-10 pt-5">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useTheme from '@/use/themeHandler'
import sidebar from './sidebar/sideBar.vue'
import sidebarHandler from '@/use/sidebarHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import projectDetailHandler from '@/use/projectDetailHandler'
import addressDetailHandler from '@/use/addressDetailHandler'
import apiHandler from '@/use/apiHandler'
import { useRoute } from 'vue-router'
import { watch, ref, onMounted, onUnmounted } from 'vue'

const themeHandler = useTheme()
const backgroundColor = themeHandler.getBackgroundColor('background')
const useSidebar = sidebarHandler()
const route = useRoute()
const useProjectDetails = projectDetailHandler()
const useAddressDetails = addressDetailHandler()
const useApi = apiHandler()

const project_name = ref('')
const address_name = ref('')
const useContentGuard = contentGuardHandler()

const showOrgSwitcher = ref(false)
const organisations = ref([])
const selectRef = ref(null)

onMounted(async () => {
  try {
    const { data: orgData } = await useApi.requestV2('get', 'v1/users/me/organizations')
    organisations.value = orgData.map((org) => ({ label: org.name, value: org.id }))
  } catch (error) {
    console.error('Error fetching organisations:', error)
  }
  getRouteName()
})

onUnmounted(() => window.removeEventListener('click', clicker))

function openOrgSwitcher() {
  if (showOrgSwitcher.value) return

  showOrgSwitcher.value = true
  if (showOrgSwitcher.value) {
    setTimeout(() => {
      window.addEventListener('click', clicker)
    }, 0)
  }
}

const clicker = (event) => {
  if (showOrgSwitcher.value && !selectRef.value.contains(event.target)) {
    showOrgSwitcher.value = false
    window.removeEventListener('click', clicker)
  }
}

// Niet de mooiste oplossing dit maar goed
function getRouteName() {
  if (route.name === 'project' || route.name === 'project-address') {
    project_name.value = useProjectDetails.project_name
  } else {
    project_name.value = ''
  }
  if (route.name === 'address' || route.name === 'project-address') {
    address_name.value = useAddressDetails.address_name
  } else {
    address_name.value = ''
  }
}

watch(
  () => route.name,
  () => {
    getRouteName()
  }
)

async function changeOrganisation(orgId) {
  try {
    await useApi.requestV2('patch', 'v1/users/me', { active_org_id: orgId })
    window.location.reload()
  } catch (error) {
    console.error('Error changing organisation:', error)
  }
}
</script>
