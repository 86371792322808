<template>
  <div class="flex flex-wrap md:items-center mb-2 w-full">
    <div class="md:w-1/3">
      <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-2 py-2 text-sm">
        {{ label }}
      </label>
    </div>
    <div class="md:w-2/3 flex flex-row">
      <input
        :value="itemDisplayTemplate(items)"
        v-bind="$attrs"
        disabled="true"
        class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
        @input="handleInput($event)"
      />
      <baseButton size="small" class="ml-2" @click="showSelectModal = true">
        <featherIcon class="w-4" icon="UsersIcon" />
      </baseButton>
      <div></div>
    </div>
  </div>

  <baseModal v-if="showSelectModal" @close="showSelectModal = false" title="Select">
    <baseTable
      :fields="fields"
      :records="items"
      :add-button="true"
      :search="true"
      :selectable="true"
      @add="onOpenAdd"
      @selection="items = $event"
    />
    <template #buttons>
      <baseButton @action="select">Select</baseButton>
    </template>
  </baseModal>

  <baseModal v-if="showAddModal" @close="showAddModal = false" title="Add">
    <slot name="add-form" />
    <template #buttons>
      <baseButton @action="add">Add</baseButton>
    </template>
  </baseModal>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps(['modelValue', 'label', 'fields', 'items', 'itemDisplayTemplate'])
const emit = defineEmits(['update:modelValue', 'add'])

const showSelectModal = ref(false)
const showAddModal = ref(false)
const items = ref([])

items.value = [...props.modelValue]

function select() {
  emit('update:modelValue', items.value)
  showSelectModal.value = false
}

function onOpenAdd() {
  showAddModal.value = true
}

function add() {
  emit('add')
  showAddModal.value = false
}
</script>
