import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import { ref, computed } from 'vue'

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const organisationForm = formHandler()
  const organisation = ref({})

  const organisationDetailFields = ref([
    {
      label: 'Template',
      key: 'template',
      type: 'file',
      accept: '.docx',
    },
    {
      label: 'Template basic',
      key: 'template_basic',
      type: 'file',
      accept: '.docx',
    },
  ])

  function getDetails(payload) {
    loadHandler.setLoadingState('get_organisation_details', true)
    const organisation_id = payload.id

    useApi.requestV2('get', `admin/organizations/${organisation_id}`).then((response) => {
      organisation.value = response.data
      organisationForm.create({
        type: 'edit',
        fields: organisationDetailFields.value,
        data: response.data,
      })

      loadHandler.setLoadingState('get_organisation_details', false)
    })
  }

  function updateOrganisation() {
    loadHandler.setLoadingState('update_organisation', true)
    const formData = organisationForm.data.value
    let payload = new FormData()

    // Handle main template
    if (formData.template instanceof File) {
      payload.append('file', formData.template)
    } else if (formData.template === null || formData.template === '') {
      payload.append('delete_file', 'true')
    }

    // Handle basic template
    if (formData.template_basic instanceof File) {
      payload.append('file_basic', formData.template_basic)
    } else if (formData.template_basic === null || formData.template_basic === '') {
      payload.append('delete_file_basic', 'true')
    }

    payload.append('organisation', organisation.value.id)

    const headers = {
      'Content-Type': 'multipart/form-data',
    }

    useApi
      .requestV2('upload', `admin/organizations/${organisation.value.id}`, payload, headers)
      .then(() => {
        loadHandler.setLoadingState('update_organisation', false)
      })
      .catch((error) => {
        console.error('Error updating organisation:', error)
        loadHandler.setLoadingState('update_organisation', false)
        // Handle error (e.g., show error message to user)
      })
  }

  const org_name = computed(() => {
    if ('display_name' in organisation.value) {
      return organisation.value.display_name
    } else {
      return ''
    }
  })

  return {
    loadHandler,
    organisationForm,
    getDetails,
    organisation,
    org_name,
    updateOrganisation,
  }
}
