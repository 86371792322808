import { ref, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import selectValues from '@/use/selects/values'
import formHandler from '@/use/formHandler'
import loadingHandler from '@/use/loadingHandler'
import klimaatzoneHandler from '@/use/handlerKlimaatzone'
import formuleHandler from '@/use/formuleHandler'

import maatregelenHandler from '@/use/maatregelenHandler'
import hypotheekhandler from '@/use/hypotheekHandler'
import grafiekenHandler from '@/use/grafiekenHandler'
import zonnepanelenListDisplay from '@/components/extended/zonnepanelenListDisplay.vue'
import lookupHandler from './lookupHandler'
import toastHandler from '@/use/toastHandler'

export default (options) => {
  // data
  let surveyData = ref({})
  let useFormulaHuidig = ref(null)
  let useFormulaNieuw = ref(null)
  let useFormulaHuidig20 = ref(null)
  let useFormulaNieuw20 = ref(null)
  const notFound = ref(false)

  // handlers
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const useLookup = lookupHandler()
  const useToast = toastHandler()

  // forms
  const soortForm = formHandler()
  const installatieForm = formHandler()
  const klimaatZonesForm = klimaatzoneHandler()
  const installatieFormNieuw = formHandler()
  const klimaatZonesFormNieuw = klimaatzoneHandler()

  // formulas
  const form_soort = soortForm
  const form_installaties_huidig = installatieForm
  const form_installaties_nieuw = installatieFormNieuw
  const form_klimaatzones_huidig = klimaatZonesForm
  const form_klimaatzones_nieuw = klimaatZonesFormNieuw

  // maatregelen
  const forms = {
    soortForm,
    form_klimaatzones_huidig,
    form_klimaatzones_nieuw,
  }

  const useMaatregelen = maatregelenHandler(
    forms,
    useFormulaHuidig,
    useFormulaNieuw,
    form_installaties_huidig,
    form_installaties_nieuw
  )

  const formule_payload_huidig = {
    soort: form_soort,
    installaties: form_installaties_huidig,
    klimaatzones: form_klimaatzones_huidig,
  }

  const formule_payload_nieuw = {
    soort: form_soort,
    installaties: form_installaties_nieuw,
    klimaatzones: form_klimaatzones_nieuw,
  }

  // hypotheek
  const useHypotheek = hypotheekhandler(useMaatregelen)

  // grafieken
  const useGrafieken = grafiekenHandler(useMaatregelen, form_soort)

  async function getRecord() {
    try {
      loadHandler.setLoadingState('get_records', true)

      const response = await useApi.requestV2('get', `v1/surveys/${options.id}`)
      const result = response.data

      loadDetails(result)

      if (result.project) {
        const measure_categories = await useApi.requestV2(
          'get',
          `v1/projects/${result.project}/config/measure_categories`
        )
        surveyData.value.measure_categories = measure_categories.data
      }

      // select fieldsconfig based on basic setting
      let algemeenFieldConfig = {}
      let installatieFieldConfig = {}

      if (result.basic === true) {
        algemeenFieldConfig = surveyDetailFieldsBasic.value
        installatieFieldConfig = installatieDetailFieldsBasic.value
      } else {
        algemeenFieldConfig = surveyDetailFields.value
        installatieFieldConfig = installatieDetailFields.value
      }

      // soortform
      soortForm.create({
        type: 'edit',
        fields: algemeenFieldConfig,
        data: result.config.soort_woning,
      })

      // installatieform
      installatieForm.create({
        type: 'edit',
        fields: installatieFieldConfig,
        data: result.config.installaties,
      })

      // installatieform nieuw
      installatieFormNieuw.create({
        type: 'edit',
        fields: installatieFieldConfig,
        data: result.config.installaties_nieuw,
      })

      if ('klimaatzones_huidig' in result.config) {
        klimaatZonesForm.load(result.config.klimaatzones_huidig)
      }

      if ('klimaatzones_nieuw' in result.config) {
        klimaatZonesFormNieuw.load(result.config.klimaatzones_nieuw)
      }

      if ('hypotheek' in result.config) {
        // eslint-disable-next-line vue/no-ref-as-operand
        useHypotheek.load(result.config.hypotheek)
      }

      if ('maatregelen' in result.config) {
        // eslint-disable-next-line vue/no-ref-as-operand
        useMaatregelen.load(result.config.maatregelen)
      }

      // default formulas
      useFormulaHuidig.value = formuleHandler(formule_payload_huidig, null, null, result.basic, null)
      useFormulaNieuw.value = formuleHandler(formule_payload_nieuw, useFormulaHuidig, null, result.basic, null)

      // formulas with fixed setpoint
      useFormulaHuidig20.value = formuleHandler(formule_payload_huidig, null, 20, result.basic, true)
      useFormulaNieuw20.value = formuleHandler(formule_payload_nieuw, useFormulaHuidig20, 20, result.basic, true)
      loadHandler.setLoadingState('get_records', false)
      return surveyData
    } catch (error) {
      notFound.value = true
      console.log(error)
    }
  }

  function calculateZonnepanelenProductie(data) {
    // Variables to store input values
    let aantalPanelen = Number(data.aantal)
    let vermogenPerPaneel = Number(data.vermogen)
    let orientatie = data.orientatie
    let hellingshoek = Number(data.hellingshoek)
    let beschaduwing = Number(data.beschaduwing)
    let omvormer = data.omvormer
    let correctie_zonuren = 0.9

    if (
      !data.aantal ||
      !data.vermogen ||
      !data.orientatie ||
      data.hellingshoek === undefined ||
      data.hellingshoek === null ||
      data.beschaduwing === undefined ||
      data.beschaduwing === null ||
      !data.omvormer
    ) {
      return 0
    }

    let lookupOmvormer = {
      'Micro-omvormers': 0.04, // 4% as a decimal
      Optimizers: 0.02, // 2% as a decimal
      'String-omvormer': 0.0, // 0% as a decimal
    }

    // Replicating INDEX using the matched indices.
    let orientatieCorrectie = useLookup.zonnepanelenOrientatieHellingshoek[orientatie][hellingshoek] * correctie_zonuren

    // Replicating the LOOKUP function.
    let omvormerCorrectie = lookupOmvormer[omvormer]

    let result =
      aantalPanelen *
      vermogenPerPaneel *
      (1 - beschaduwing / 100) *
      (orientatieCorrectie / 100) *
      (1 + omvormerCorrectie)
    return String(Math.round(result))
  }

  function editRecord() {
    return new Promise(function (resolve) {
      // gather results
      // Define a function to remove the 'second_loop' key
      function removeSecondLoop(data) {
        // Check if data is an object and has the property klimaatzones
        if (data && typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'klimaatzones')) {
          // Ensure klimaatzones is also an object
          if (typeof data.klimaatzones === 'object' && data.klimaatzones !== null) {
            Object.keys(data.klimaatzones).forEach((zone) => {
              if (
                data.klimaatzones[zone] &&
                Object.prototype.hasOwnProperty.call(data.klimaatzones[zone], 'second_loop')
              ) {
                delete data.klimaatzones[zone].second_loop
              }
            })
          }
        }
      }

      // Get references to your structures
      let dataHuidig = useFormulaHuidig.value.result.data_total
      let dataNieuw = useFormulaNieuw.value.result.data_total
      let dataHuidig20 = useFormulaHuidig20.value.result.data_total
      let dataNieuw20 = useFormulaNieuw20.value.result.data_total

      // Apply the function to each of the structures
      removeSecondLoop(dataHuidig)
      removeSecondLoop(dataNieuw)
      removeSecondLoop(dataHuidig20)
      removeSecondLoop(dataNieuw20)

      const payload = {
        config: {
          soort_woning: soortForm.data.value,
          installaties: installatieForm.data.value,
          installaties_nieuw: installatieFormNieuw.data.value,
          klimaatzones_huidig: klimaatZonesForm.data.value,
          klimaatzones_nieuw: klimaatZonesFormNieuw.data.value,
          maatregelen: useMaatregelen.data.value,
          hypotheek: useHypotheek.data.value,
        },
        results: {
          huidig: dataHuidig,
          nieuw: dataNieuw,
          huidig_20: dataHuidig20,
          nieuw_20: dataNieuw20,
        },
      }

      loadHandler.setLoadingState('edit_records', true)
      useApi.requestV2('patch', `v1/surveys/${options.id}`, payload).then(() => {
        const syncForms = [
          soortForm,
          installatieForm,
          installatieFormNieuw,
          klimaatZonesForm,
          klimaatZonesFormNieuw,
          useMaatregelen,
          useHypotheek,
        ]

        syncForms.forEach((form) => {
          form.dataMonitor.sync(form.data.value)
        })

        resolve()
        loadHandler.setLoadingState('edit_records', false)
      })
    })
  }

  function loadDetails(payload) {
    Object.keys(payload).forEach((key) => {
      surveyData.value[key] = payload[key]
    })
  }

  const fieldconfigZonnepanelen = [
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      required: true,
    },
    {
      label: 'Productie',
      key: 'productie',
      type: 'number',
      required: true,
      dependent_show_field_key: 'uitgebreid',
      dependent_show_field_type: 'check',
      dependent_show_values: [false],
      unit: 'kWh',
    },
    {
      label: 'Uitgebreid',
      key: 'uitgebreid',
      type: 'toggle',
      default: false,
    },
    {
      label: 'Aantal panelen',
      key: 'aantal',
      type: 'number',
      required: true,
      dependent_show_field_key: 'uitgebreid',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
    },
    {
      label: 'Vermogen per paneel',
      key: 'vermogen',
      type: 'number',
      required: true,
      dependent_show_field_key: 'uitgebreid',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      unit: 'W',
    },
    {
      label: 'Orientatie',
      key: 'orientatie',
      type: 'select',
      options: selectValues.orientatieZonnepanelen,
      required: true,
      dependent_show_field_key: 'uitgebreid',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
    },
    {
      label: 'Hellingshoek',
      key: 'hellingshoek',
      type: 'select',
      options: selectValues.beschaduwing,
      required: true,
      dependent_show_field_key: 'uitgebreid',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      unit: '°',
    },
    {
      label: 'Omvormer',
      key: 'omvormer',
      type: 'select',
      options: selectValues.omvormers,
      required: true,
      dependent_show_field_key: 'uitgebreid',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
    },
    {
      label: 'Beschaduwing',
      key: 'beschaduwing',
      type: 'select',
      options: selectValues.beschaduwing,
      dependent_show_field_key: 'uitgebreid',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      unit: '%',
    },
    {
      label: 'Productie',
      key: 'productie',
      type: 'number',
      required: true,
      dependent_show_field_key: 'uitgebreid',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      executer: calculateZonnepanelenProductie,
      disabled: true,
      unit: 'kWh',
    },
  ]

  const surveyDetailFields = ref([
    {
      label: 'Algemeen',
      type: 'subtitle',
    },
    {
      label: 'Ambitie',
      key: 'ambitie',
      type: 'select',
      options: selectValues.ambitienNiveau,
      required: true,
      default: selectValues.ambitienNiveau[1].value,
    },
    {
      label: 'Jaar verbouwing',
      key: 'jaar_verbouwing',
      type: 'select',
      options: selectValues.jaar_verbouwing,
      tooltip: true,
    },
    {
      label: 'Gezinssamenstelling',
      key: 'gezinssamenstelling',
      type: 'select',
      options: selectValues.members,
      required: true,
    },
    {
      label: 'Bouwlagen',
      key: 'bouwlagen',
      type: 'select',
      options: selectValues.bouwlagen,
    },
    {
      label: 'Soort woning',
      key: 'soort_woning',
      type: 'select',
      options: selectValues.soort_woning,
      required: true,
    },
    {
      label: 'Omgeving',
      key: 'omgeving',
      type: 'select',
      options: selectValues.omgeving,
      required: true,
    },
    {
      label: 'Soort vloer begane grond',
      key: 'soort_vloer_begane_grond',
      type: 'select',
      options: selectValues.soort_vloer,
    },
    {
      label: 'Kruipruimte',
      key: 'kruipruimte',
      type: 'select',
      options: selectValues.kruipruimte,
    },
    {
      label: 'Soort dak',
      key: 'soort_dak',
      type: 'select',
      options: selectValues.soort_dak,
    },
    {
      label: 'Soort dakbedekking',
      key: 'soort_dakbedekking',
      type: 'select',
      options: selectValues.soort_dakbedekking,
    },
    {
      label: 'Soort muren',
      key: 'soort_muren',
      type: 'select',
      options: selectValues.soort_muren,
    },
    {
      label: 'Bouwjaar woning',
      key: 'bouwjaar_woning',
      type: 'select',
      options: selectValues.bouwjaar,
      required: true,
    },
    {
      label: 'Verbruik',
      type: 'subtitle',
    },
    {
      label: 'Verbruik gas',
      key: 'verbruik_gas',
      type: 'number',
      required: true,
      unit: 'm³',
    },
    {
      label: 'Verbruik elektra',
      key: 'verbruik_elektra',
      type: 'number',
      required: true,
      unit: 'kWh',
    },
    {
      label: 'Verbruik warmte',
      key: 'verbruik_warmte',
      type: 'number',
      required: true,
      default: '0',
      tooltip: true,
      unit: 'GJ',
    },
    {
      label: 'Kosten',
      type: 'subtitle',
    },
    {
      label: 'Prijs gas',
      key: 'prijs_gas',
      type: 'number',
      required: true,
      unit: '€/m³',
    },
    {
      label: 'Prijs elektra',
      key: 'prijs_elektra',
      type: 'number',
      required: true,
      unit: '€/kWh',
    },
    {
      label: 'Prijs warmte',
      key: 'prijs_warmte',
      type: 'number',
      required: true,
      default: '0',
      unit: '€/GJ',
    },
    {
      label: 'Teruglevertarief elektra',
      key: 'teruglevertarief_elektra',
      type: 'number',
      required: true,
      unit: '€/kWh',
    },
    {
      label: 'Prijsstijging gas',
      key: 'prijsstijging_gas',
      type: 'number',
      required: true,
      unit: '%/jaar',
    },
    {
      label: 'Prijsstijging elektra',
      key: 'prijsstijging_elektra',
      type: 'number',
      required: true,
      unit: '%/jaar',
    },
    {
      label: 'Prijsstijging warmte',
      key: 'prijsstijging_warmte',
      type: 'number',
      required: true,
      default: '0',
      unit: '%/jaar',
    },
    {
      label: 'Uitstoot warmtenet',
      key: 'uitstoot_warmtenet',
      type: 'number',
      placeholder: 'Forfaitair',
      unit: 'kg CO2/GJ',
    },
  ])

  const surveyDetailFieldsBasic = ref([
    {
      label: 'Algemeen',
      type: 'subtitle',
    },
    {
      label: 'Ambitie',
      key: 'ambitie',
      type: 'select',
      options: selectValues.ambitienNiveau,
      required: true,
      default: selectValues.ambitienNiveau[1].value,
    },
    {
      label: 'Jaar verbouwing',
      key: 'jaar_verbouwing',
      type: 'select',
      options: selectValues.jaar_verbouwing,
      edit: false,
    },
    {
      label: 'Gezinssamenstelling',
      key: 'gezinssamenstelling',
      type: 'select',
      options: selectValues.members,
      required: true,
    },
    {
      label: 'Bouwlagen',
      key: 'bouwlagen',
      type: 'select',
      options: selectValues.bouwlagen,
      edit: false,
    },
    {
      label: 'Soort woning',
      key: 'soort_woning',
      type: 'select',
      options: selectValues.soort_woning,
      required: true,
    },
    {
      label: 'Omgeving',
      key: 'omgeving',
      type: 'select',
      options: selectValues.omgeving,
      required: true,
    },
    {
      label: 'Soort vloer begane grond',
      key: 'soort_vloer_begane_grond',
      type: 'select',
      options: selectValues.soort_vloer,
      edit: false,
    },
    {
      label: 'Kruipruimte',
      key: 'kruipruimte',
      type: 'select',
      options: selectValues.kruipruimte,
      edit: false,
    },
    {
      label: 'Soort dak',
      key: 'soort_dak',
      type: 'select',
      options: selectValues.soort_dak,
      edit: false,
    },
    {
      label: 'Soort dakbedekking',
      key: 'soort_dakbedekking',
      type: 'select',
      options: selectValues.soort_dakbedekking,
      edit: false,
    },
    {
      label: 'Soort muren',
      key: 'soort_muren',
      type: 'select',
      options: selectValues.soort_muren,
      edit: false,
    },
    {
      label: 'Bouwjaar woning',
      key: 'bouwjaar_woning',
      type: 'select',
      options: selectValues.bouwjaar,
      required: true,
    },
    {
      label: 'Verbruik',
      type: 'subtitle',
    },
    {
      label: 'Verbruik gas',
      key: 'verbruik_gas',
      type: 'number',
      required: true,
      unit: 'm³',
    },
    {
      label: 'Verbruik elektra',
      key: 'verbruik_elektra',
      type: 'number',
      required: true,
      unit: 'kWh',
    },
    {
      label: 'Verbruik warmte',
      key: 'verbruik_warmte',
      type: 'number',
      required: true,
      default: '0',
      edit: false,
      unit: 'GJ',
    },
    {
      label: 'Prijs gas',
      key: 'prijs_gas',
      type: 'number',
      required: true,
      unit: '€/m³',
    },
    {
      label: 'Prijs elektra',
      key: 'prijs_elektra',
      type: 'number',
      required: true,
      unit: '€/kWh',
    },
    {
      label: 'Prijs warmte',
      key: 'prijs_warmte',
      type: 'number',
      required: true,
      default: '0',
      edit: false,
      unit: '€/GJ',
    },
    {
      label: 'Teruglevertarief elektra',
      key: 'teruglevertarief_elektra',
      type: 'number',
      required: true,
      unit: '€/kWh',
    },
    {
      label: 'Prijsstijging gas',
      key: 'prijsstijging_gas',
      type: 'number',
      required: true,
      edit: false,
      unit: '%/jaar',
    },
    {
      label: 'Prijsstijging elektra',
      key: 'prijsstijging_elektra',
      type: 'number',
      required: true,
      edit: false,
      unit: '%/jaar',
    },
    {
      label: 'Prijsstijging warmte',
      key: 'prijsstijging_warmte',
      type: 'number',
      required: true,
      default: '0',
      edit: false,
      unit: '%/jaar',
    },
    {
      label: 'Uitstoot warmtenet',
      key: 'uitstoot_warmtenet',
      type: 'number',
      placeholder: 'Forfaitair',
      edit: false,
      unit: 'kg CO2/GJ',
    },
  ])

  const installatieDetailFields = ref([
    {
      label: 'Algemeen',
      type: 'subtitle',
    },
    {
      label: 'Warmteopwekker',
      key: 'warmteopwekker',
      type: 'select',
      options: selectValues.warmteopwekkerNieuw,
      required: true,
    },
    {
      label: 'Opwekking binnen thermische schil',
      key: 'opwekking_binnen_thermische_schil',
      type: 'select',
      options: selectValues.boolean,
      required: true,
    },
    {
      label: 'Distributieleidingen buiten thermische schil',
      key: 'leidingen_buiten_schil',
      type: 'select',
      options: selectValues.distributiesysteem,
      required: true,
    },
    {
      label: 'Bron warm tapwater',
      key: 'bron_warm_tapwater',
      type: 'select',
      options: selectValues.bronTapwaterNieuw,
      required: true,
    },
    {
      label: 'Zonneboiler voor warm tapwater',
      key: 'zonneboiler_voor_bron_warm_tapwater',
      type: 'select',
      options: selectValues.opwekking,
      required: true,
    },
    {
      label: 'Aansluitwaarde elektra',
      key: 'aansluitwaarde_elektra',
      type: 'select',
      options: selectValues.aansluitwaarde,
      required: true,
    },
    {
      label: 'Koken',
      key: 'koken',
      type: 'select',
      options: selectValues.koken,
      required: true,
    },
    {
      label: 'Accu',
      key: 'accu',
      type: 'number',
      required: true,
      default: '0',
      unit: 'kWh',
    },
    {
      label: 'Nachtverlaging',
      key: 'nachtverlaging',
      type: 'select',
      options: selectValues.boolean,
      required: true,
    },
    {
      label: 'Verbruiksprofiel tapwater',
      key: 'verbruiksprofiel_tapwater',
      type: 'select',
      options: selectValues.verbruik_tapwater,
      required: true,
    },
    {
      label: 'Ventilatie',
      type: 'subtitle',
    },
    {
      label: 'Ventilatie (natuurlijk)',
      key: 'ventilatie_natuurlijk',
      type: 'select',
      options: selectValues.ventilatie,
      required: true,
    },
    {
      label: 'Ventilatie (mechanisch)',
      key: 'ventilatie_mechanisch',
      type: 'number',
      required: true,
      unit: 'm³/h',
    },
    {
      label: 'Ventilatie (WTW)',
      key: 'ventilatie_wtw',
      type: 'number',
      required: true,
      unit: 'm³/h',
    },
    {
      label: 'Luchtdichtheid',
      key: 'luchtdichtheid',
      type: 'number',
      placeholder: 'Forfaitair',
      unit: 'dm³/s*m²',
    },
    {
      label: 'Zonnepanelen',
      type: 'subtitle',
    },
    {
      label: 'Opstellingen',
      key: 'zonnepanelen',
      type: 'list',
      displayTemplate: zonnepanelenListDisplay,
      modalSize: 's',
      fieldConfig: fieldconfigZonnepanelen,
      clickToEdit: true,
    },
    {
      label: 'Aandeel direct verbruik',
      key: 'aandeel_direct_verbruik',
      type: 'percentage',
      required: true,
      default: '30',
      unit: '%',
    },
  ])

  const installatieDetailFieldsBasic = ref([
    {
      label: 'Algemeen',
      type: 'subtitle',
      edit: false,
    },
    {
      label: 'Warmteopwekker',
      key: 'warmteopwekker',
      type: 'select',
      options: selectValues.warmteopwekkerNieuw,
      required: true,
      edit: false,
    },
    {
      label: 'Opwekking binnen thermische schil',
      key: 'opwekking_binnen_thermische_schil',
      type: 'select',
      options: selectValues.boolean,
      required: true,
      edit: false,
    },
    {
      label: 'Distributieleidingen buiten thermische schil',
      key: 'leidingen_buiten_schil',
      type: 'select',
      options: selectValues.distributiesysteem,
      required: true,
      edit: false,
    },
    {
      label: 'Bron warm tapwater',
      key: 'bron_warm_tapwater',
      type: 'select',
      options: selectValues.bronTapwaterNieuw,
      required: true,
      edit: false,
    },
    {
      label: 'Zonneboiler voor warm tapwater',
      key: 'zonneboiler_voor_bron_warm_tapwater',
      type: 'select',
      options: selectValues.opwekking,
      required: true,
      edit: false,
    },
    {
      label: 'Aansluitwaarde elektra',
      key: 'aansluitwaarde_elektra',
      type: 'select',
      options: selectValues.aansluitwaarde,
      required: true,
      edit: false,
    },
    {
      label: 'Koken',
      key: 'koken',
      type: 'select',
      options: selectValues.koken,
      required: true,
      edit: false,
    },
    {
      label: 'Aandeel direct verbruik',
      key: 'aandeel_direct_verbruik',
      type: 'percentage',
      required: true,
      default: '30',
      edit: false,
      unit: '%',
    },
    {
      label: 'Accu',
      key: 'accu',
      type: 'number',
      required: true,
      default: '0',
      edit: false,
      unit: 'kWh',
    },
    {
      label: 'Nachtverlaging',
      key: 'nachtverlaging',
      type: 'select',
      options: selectValues.boolean,
      required: true,
      edit: false,
    },
    {
      label: 'Verbruiksprofiel tapwater',
      key: 'verbruiksprofiel_tapwater',
      type: 'select',
      options: selectValues.verbruik_tapwater,
      required: true,
      edit: false,
    },
    {
      label: 'Ventilatie',
      type: 'subtitle',
    },
    {
      label: 'Ventilatie',
      key: 'ventilatie_basic_select',
      type: 'select',
      options: selectValues.ventilatie_basic,
      required: true,
    },
    {
      label: 'Ventilatie (natuurlijk)',
      key: 'ventilatie_natuurlijk',
      type: 'select',
      options: selectValues.ventilatie,
      edit: false,
      required: true,
    },
    {
      label: 'Ventilatie (mechanisch)',
      key: 'ventilatie_mechanisch',
      type: 'number',
      required: true,
      edit: false,
      unit: 'm³/h',
    },
    {
      label: 'Ventilatie (WTW)',
      key: 'ventilatie_wtw',
      type: 'number',
      required: true,
      edit: false,
      unit: 'm³/h',
    },
    {
      label: 'Luchtdichtheid',
      key: 'luchtdichtheid',
      type: 'number',
      placeholder: 'Forfaitair',
      edit: false,
      unit: 'dm³/s*m²',
    },
    {
      label: 'Zonnepanelen',
      type: 'subtitle',
    },
    {
      label: 'Opstellingen',
      key: 'zonnepanelen',
      type: 'list',
      displayTemplate: zonnepanelenListDisplay,
      modalSize: 's',
      fieldConfig: fieldconfigZonnepanelen,
    },
  ])

  function generateReport(useFormulaHuidig, useFormulaNieuw, grafieken, versionPayload) {
    loadHandler.setLoadingState('generate_report', true)

    let payload = JSON.parse(JSON.stringify(options))

    const { id, ...payloadWithoutId } = payload

    payloadWithoutId.berekend_huidig = useFormulaHuidig.value.result.data_total
    payloadWithoutId.berekend_nieuw = useFormulaNieuw.value.result.data_total
    payloadWithoutId.berekend_huidig20 = useFormulaHuidig20.value.result.data_total
    payloadWithoutId.berekend_nieuw20 = useFormulaNieuw20.value.result.data_total

    const dingen = {
      rentepercentage: JSON.parse(JSON.stringify(useHypotheek.data.value.rentepercentage)),
      looptijd: JSON.parse(JSON.stringify(useHypotheek.data.value.looptijd)),
      bedrag: JSON.parse(JSON.stringify(useHypotheek.bedrag.value)),
      maandlasten: JSON.parse(JSON.stringify(useHypotheek.maandlasten.value)),
      maandlasten_totaal: JSON.parse(JSON.stringify(useHypotheek.maanlasten_totaal.value)),
      anders_financieren: JSON.parse(JSON.stringify(useHypotheek.data.value.anders_financieren)),
    }

    payloadWithoutId.hypotheek = dingen
    payloadWithoutId.kosten = JSON.parse(JSON.stringify(useMaatregelen.costs.value))

    payloadWithoutId.grafieken = grafieken
    payloadWithoutId.frontend_versies = versionPayload

    useApi
      .requestV2('post', `v1/surveys/${id}/generate`, payloadWithoutId, { responseType: 'blob' })
      .then((result) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        const today = new Date().toLocaleDateString()
        const reportName = `Woningtool rapportage ${today}.docx`
        link.setAttribute('download', reportName)
        document.body.appendChild(link)
        link.click()
        link.remove()

        loadHandler.setLoadingState('generate_report', false)
      })
      .catch(() => {
        useToast.addToast(`Probleem bij exporteren`, 'danger')
        loadHandler.setLoadingState('generate_report', false)
      })
  }

  let isEmpty = (a) => Array.isArray(a) && a.every(isEmpty)

  const showBerekening = computed(() => {
    const errors = [
      soortForm.errors.value,
      installatieForm.errors.value,
      installatieFormNieuw.errors.value,
      klimaatZonesForm.errors.value,
      klimaatZonesFormNieuw.errors.value,
    ]
    const noErrors = isEmpty(errors)

    if (useFormulaHuidig.value && useFormulaNieuw.value) {
      if (noErrors) {
        useFormulaHuidig.value.locked = false
        useFormulaNieuw.value.locked = false
        useFormulaHuidig20.value.locked = false
        useFormulaNieuw20.value.locked = false
      } else {
        useFormulaHuidig.value.locked = true
        useFormulaNieuw.value.locked = true
        useFormulaHuidig20.value.locked = true
        useFormulaNieuw20.value.locked = true
      }
    }

    return isEmpty(errors)
  })

  function getUniqueValues(arrays) {
    const flattenedArray = [].concat(...arrays)
    const uniqueArray = Array.from(new Set(flattenedArray))
    return uniqueArray
  }

  const uniekeKlimaatzones = computed(() => {
    const zonenamen_huidig = klimaatZonesForm.data.value.map((object) => object.naam)
    const zonenamen_nieuw = klimaatZonesFormNieuw.data.value.map((object) => object.naam)
    return getUniqueValues([zonenamen_huidig, zonenamen_nieuw])
  })

  const allErrors = computed(() => {
    const errors = {
      algemeen: {
        label: 'Algemeen',
        errors: soortForm.errors.value,
      },
      installaties_huidig: {
        label: 'Installaties huidig',
        errors: installatieForm.errors.value,
      },
      installaties_nieuw: {
        label: 'Installaties nieuw',
        errors: installatieFormNieuw.errors.value,
      },
      klimaatzones_huidig: {
        label: 'Klimaatzones huidig',
        errors: klimaatZonesForm.errors.value,
      },
      klimaatzones_nieuw: {
        label: 'Klimaatzones nieuw',
        errors: klimaatZonesFormNieuw.errors.value,
      },
    }

    return errors
  })

  return {
    surveyData,
    getRecord,
    soortForm,
    installatieForm,
    installatieFormNieuw,
    loadHandler,
    klimaatZonesForm,
    klimaatZonesFormNieuw,
    editRecord,
    generateReport,
    showBerekening,
    allErrors,
    useFormulaHuidig,
    useFormulaNieuw,
    useFormulaHuidig20,
    useFormulaNieuw20,
    useMaatregelen,
    useHypotheek,
    useGrafieken,
    notFound,
    uniekeKlimaatzones,
  }
}
