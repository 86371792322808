<template>
  <div class="h-full min-h-0 overflow-y-auto">
    <div class="flex flex-col">
      <div class="flex justify-between items-center">
        <basePageTitle :title="useContentGuard.organisation.value" subtitle="Taken" />
      </div>
      <div class="flex flex-col gap-4 mt-4">
        <div v-for="project in projects" :key="project.id" class="w-full text-slate-700">
          <h2 class="text-2xl font-semibold text-center mb-4">{{ project.name }}</h2>
          <div class="pb-2 pr-1">
            <baseTable
              :fields="taskFields"
              :records="project.tasks"
              :records-per-page="10"
              actionField="Afgerond"
              @action="completeStep"
              actionValue="completed"
              :sortable="true"
              :selectable="true"
              :selectAllOption="true"
              :editButton="true"
              @edit="editTask"
              :multiEdit="true"
              @multi_edit="onMultiEdit"
            />
          </div>
        </div>
      </div>
    </div>
    <baseModal v-if="showEditTaskForm" @close="showEditTaskForm = false" :title="$translate('Edit')">
      <baseForm :handler="editTaskForm" />
      <template v-slot:buttons>
        <baseButton @action="editTasks()">
          {{ $translate('Edit') }}
        </baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import apiHandler from '@/use/apiHandler'
import formHandler from '@/use/formHandler'
import loadingHandler from '@/use/loadingHandler'

const useContentGuard = contentGuardHandler()
const useApi = apiHandler()
const { withLoading } = loadingHandler()

const users = ref([])
const projects = ref([])
const showEditTaskForm = ref(false)
const editTaskForm = formHandler()
const selectedTasks = ref([])

const taskFields = [
  { label: 'Taak', key: 'name', type: 'string' },
  { label: 'Deadline', key: 'deadline', type: 'date' },
  { label: 'Gebruiker', key: 'user_name', type: 'string' },
  { label: 'Adres', key: 'address_full', type: 'string' },
  { label: 'Omschrijving', key: 'description', type: 'string' },
  { label: 'Stap', key: 'step_name', type: 'string' },
]

onMounted(async () => {
  try {
    const { data: projectsData } = await useApi.requestV2('get', `v1/projects/tasks`)

    projects.value = projectsData.map((project) => ({
      ...project,
      tasks: project.steps.flatMap((step) =>
        step.tasks.map((task) => ({
          ...task,
          address_full: `${task.address?.address} ${task.address?.place}, ${task.address?.zip}`,
          step_name: step.name,
          user_name: task.users?.map((user) => user.name).join(', '),
        }))
      ),
    }))

    await loadUsers()

    initializeForms()
  } catch (error) {
    console.error('Error fetching data:', error)
  }
})

async function loadUsers() {
  const usersResponse = await useApi.requestV2('get', 'v1/organizations/users')
  users.value = usersResponse.data.map((user) => ({ label: user.name, value: user.id }))
}

function initializeForms() {
  const editTaskFormFields = [
    { label: 'Naam', key: 'name', type: 'string' },
    { label: 'Deadline', key: 'deadline', type: 'date' },
    { label: 'Gebruiker', key: 'users', type: 'select', options: users },
    { label: 'Omschrijving', key: 'description', type: 'string' },
  ]

  editTaskForm.create({
    type: 'edit',
    fields: editTaskFormFields,
  })
}
const completeStep = async (record, value) => {
  try {
    projects.value = projects.value.map((project) => ({
      ...project,
      tasks: project.tasks.map((task) => (task.id === record.id ? { ...task, completed: value } : task)),
      steps: project.steps.map((step) => ({
        ...step,
        finished: step.tasks.filter((task) => task.completed).length,
      })),
    }))
    await useApi.requestV2('patch', `v1/tasks/${record.id}`, { completed: value })
  } catch (error) {
    console.error('Error updating task:', error)
  }
}

const editTask = (record) => {
  showEditTaskForm.value = true
  if (record.users !== null && record.users.length > 0) {
    record.users = record.users[0].id
  } else {
    record.users = ''
  }
  editTaskForm.data.value = record
  selectedTasks.value = [record]
}

const onMultiEdit = (records) => {
  showEditTaskForm.value = true
  const record = records[0]
  editTaskForm.data.value = record
  selectedTasks.value = records
}

const editTasks = withLoading('edit_tasks', async () => {
  try {
    const payload = selectedTasks.value.map((task) => ({
      id: task.id,
      name: editTaskForm.data.value.name,
      deadline: editTaskForm.data.value.deadline,
      description: editTaskForm.data.value.description,
      users: editTaskForm.data.value.users.map((user) => user.id),
    }))
    await useApi.requestV2('patch', `v1/tasks`, payload)
    showEditTaskForm.value = false
  } catch (error) {
    console.error('Error updating tasks:', error)
  }
})
</script>
