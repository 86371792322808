import { readonly, ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'

export default () => {
  // data
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)
  let address = ref(null)

  // handlers
  const loadHandler = loadingHandler()
  const addForm = formHandler()
  const editForm = formHandler()
  const useApi = apiHandler()

  const fields = ref([
    {
      label: 'Project ID',
      key: 'id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'name',
      key: 'name',
      type: 'string',
    },
    {
      label: 'Description',
      key: 'description',
      type: 'string',
    },
    {
      label: 'Start date',
      key: 'start_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
    {
      label: 'End date',
      key: 'end_date',
      type: 'date',
      filter: 'dateFromEpochDay',
    },
  ])

  // list
  function getRecords() {
    loadHandler.setLoadingState('get_records', true)

    const requestFields = ['id', 'name', 'description', 'start_date', 'end_date']
    const fieldsParam = '?fields=' + requestFields.join(',')
    useApi.requestV2('get', `v1/projects/${fieldsParam}`).then((result) => {
      const data = result.data
      records.value = data
      loadHandler.setLoadingState('get_records', false)
    })
  }

  // add
  function addRecord() {
    const payload = addForm.data.value
    loadHandler.setLoadingState('add_record', true)
    useApi.requestV2('post', `v1/projects`, payload).then(() => {
      loadHandler.setLoadingState('add_record', false)
      showAdd.value = false
      getRecords(address.value)
    })
  }

  function openAdd(prefill) {
    const formOptions = {
      type: 'add',
      fields: fields.value,
      data: {
        start_date: new Date().getTime(),
        end_date: new Date().getTime(),
      },
    }

    if (prefill) {
      formOptions.data = prefill
    }

    addForm.create(formOptions)
    showAdd.value = true
  }

  function closeEdit() {
    editForm.data.value = {}
    showEdit.value = false
    address.value = null
  }

  function closeAdd() {
    showAdd.value = false
    address.value = null
    addForm.close()
  }

  function deleteRecord(value) {
    loadHandler.setLoadingState('delete_record', true)
    useApi.requestV2('delete', `v1/projects/${value}`).then(() => {
      loadHandler.setLoadingState('delete_record', false)
      getRecords()
    })
  }

  // exposed
  return {
    fields: readonly(fields),
    records: readonly(records),
    getRecords,
    loadHandler,
    deleteRecord,
    showAdd,
    showEdit,
    addForm,
    addRecord,
    editForm,
    closeEdit,
    openAdd,
    closeAdd,
  }
}
