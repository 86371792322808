<template>
  <div class="flex flex-row mt-4">
    <div class="basis-1/2 mr-4">
      <div class="flex flex-col">
        <baseCard v-if="!isLoadingDetails" class="overflow-y-auto">
          <base-key-value :data="project" :fields="detailFields" />
        </baseCard>
        <baseCard class="mt-4">
          <div v-if="!isLoadingTasks" class="flex flex-row justify-around">
            <basePieGraph
              title="Voltooide Stappen"
              class="w-60 h-60"
              :completedCount="completedSteps"
              :totalCount="steps.length"
            />
            <basePieGraph
              title="Voltooide Taken"
              class="w-60 h-60"
              :completedCount="finishedTasks.length"
              :totalCount="tasks.length"
            />
          </div>
        </baseCard>

        <baseCard class="mt-4">
          <h3 class="text-lg font-semibold mb-4">Voortgang per stap</h3>
          <div v-for="step in steps" :key="step.id" class="mb-4">
            <p class="mb-2">{{ step.name }}</p>
            <baseProgressBar
              :percentage="calculateStepCompletion(step).percentage"
              :label="`${calculateStepCompletion(step).completed} van ${calculateStepCompletion(step).total} voltooid`"
            />
          </div>
        </baseCard>
      </div>
    </div>

    <div class="basis-1/2">
      <div v-show="!isLoadingDetails">
        <div class="w-full h-96 z-0 rounded shadow-md bg-white ml-2" id="map_overview"></div>
      </div>
    </div>
  </div>

  <div v-if="!isLoadingDetails" class="flex flex-row mt-4">
    <div class="w-full">
      <basePageTitle title="Adressen" class="my-8" />
      <baseTable
        :fields="addressFields"
        :records="project.addresses"
        :records-per-page="10"
        :add-button="true"
        :delete-button="true"
        :clickAble="true"
        :clickCallback="navigateToAddress"
        :loading="isLoading"
        @add="openAddAddresses"
        @delete="unassignAddress"
      />

      <baseModal v-if="showAddAddresses" @close="closeAddAddresses" :title="$translate('Add')" size="xl">
        <baseTable
          :fields="addressFields"
          :records="unassignedAddresses"
          :records-per-page="10"
          :clickAble="true"
          :clickCallback="navigateToAddress"
          :selectable="true"
          :selectAllOption="true"
          :hoverEffect="true"
          :loading="isLoadingUnassigned"
          @selection="setSelectedUnassignedAddresses"
        />
        <template #buttons>
          <baseButton @action="assignAddresses" :loading="isAssigningAddresses">
            {{ $translate('Add') }}
          </baseButton>
        </template>
      </baseModal>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import apiHandler from '@/use/apiHandler'
import basePieGraph from '@/components/extended/basePieGraph.vue'
import loadingHandler from '@/use/loadingHandler'
import nodeMapHandlerMultiple from '@/use/nodeMapHandlerMultiple'

const router = useRouter()
const route = useRoute()
const { states, somethingLoading, withLoading } = loadingHandler()
const useApi = apiHandler()
const useNodeMapMultiple = nodeMapHandlerMultiple()

const project = ref({})
const steps = ref([])
const tasks = computed(() => steps.value.flatMap((step) => step.tasks))
const finishedTasks = computed(() => tasks.value.filter((task) => task.completed))
const showAddAddresses = ref(false)
const unassignedAddresses = ref([])
const selectedUnassignedAddresses = ref([])
const recordsLoaded = ref(false)
const mapInitialized = ref(false)
const overviewMap = ref(null)
const overviewMapMarkers = ref(null)

const isLoadingDetails = computed(() => states.get_details)
const isLoadingTasks = computed(() => states.get_tasks)
const isLoading = computed(() => somethingLoading.value)
const isLoadingUnassigned = computed(() => states.get_unassigned)
const isAssigningAddresses = computed(() => states.assign_addresses)

const completedSteps = computed(() => {
  let count = 0
  for (const step of steps.value) {
    if (step.tasks.find((task) => task.completed === false) === undefined) {
      count++
    } else {
      break
    }
  }
  return count
})
const calculateStepCompletion = (step) => {
  const addressesWithStep = project.value.addresses.filter((address) => address.steps.some((s) => s.id === step.id))
  const completedAddresses = addressesWithStep.filter((address) =>
    address.steps.find((s) => s.id === step.id).tasks.every((task) => task.completed)
  )
  const completed = completedAddresses.length
  const total = addressesWithStep.length
  return {
    completed,
    total,
    percentage: Math.round((completed / total) * 100) || 0,
  }
}

const detailFields = [
  { label: 'Name', key: 'name', type: 'string' },
  { label: 'Description', key: 'description', type: 'string' },
  { label: 'Start date', key: 'start_date', type: 'date', filter: 'dateFromEpochDay' },
  { label: 'End date', key: 'end_date', type: 'date', filter: 'dateFromEpochDay' },
]

const addressFields = [
  { label: 'Address ID', key: 'id', type: 'id', table: false, add: false, edit: false },
  { label: 'address', key: 'address', type: 'string' },
  { label: 'zip', key: 'zip', type: 'string' },
  { label: 'place', key: 'place', type: 'string' },
  { label: 'Huidige stap', key: 'current_step', type: 'string' },
]

onMounted(async () => {
  await getDetails()
  await getTasks()
})

watch(
  recordsLoaded,
  (newRecordsLoaded) => {
    if (newRecordsLoaded && !mapInitialized.value) {
      nextTick(() => initializeMap())
    }
  },
  { immediate: true }
)

const getDetails = withLoading('get_details', async () => {
  const project_id = route.params.projectId
  const [projectResponse, addressesResponse] = await Promise.all([
    useApi.requestV2('get', `v1/projects/${project_id}`),
    useApi.requestV2('get', `v1/projects/${project_id}/addresses`),
  ])
  project.value = { ...projectResponse.data, addresses: addressesResponse.data }
  recordsLoaded.value = true
})

const getTasks = withLoading('get_tasks', async () => {
  const response = await useApi.requestV2('get', `v1/projects/${route.params.projectId}/steps/tasks`)
  steps.value = response.data.map((step) => ({
    ...step,
    completed: step.tasks.every((task) => task.completed),
  }))
  project.value.addresses.forEach((address) => {
    address.steps = steps.value.map((step) => ({
      ...step,
      tasks: step.tasks.filter((task) => task.address_id === address.id),
    }))
  })
  project.value.addresses.forEach((address) => {
    address.current_step = address.steps.find((step) => step.tasks.some((task) => !task.completed))?.name ?? 'Klaar'
  })
})

function initializeMap() {
  const settings = {
    markerType: 'pinMarker',
    nodes: project.value.addresses,
  }
  const { map, marker } = useNodeMapMultiple.renderMap('map_overview', 'minimap', false, settings)
  overviewMap.value = map
  overviewMapMarkers.value = marker
  mapInitialized.value = true
}

const getUnassignedAddresses = withLoading('get_unassigned', async () => {
  const response = await useApi.requestV2('get', `v1/addresses/projects/${route.params.projectId}/unassigned`)
  unassignedAddresses.value = response.data
})

function openAddAddresses() {
  showAddAddresses.value = true
  getUnassignedAddresses()
}

function closeAddAddresses() {
  showAddAddresses.value = false
  unassignedAddresses.value = []
  selectedUnassignedAddresses.value = []
}

const assignAddresses = withLoading('assign_addresses', async () => {
  await useApi.requestV2(
    'post',
    `v1/projects/${route.params.projectId}/addresses`,
    selectedUnassignedAddresses.value.map((address) => address.id)
  )
  closeAddAddresses()
  await getDetails()
})

const unassignAddress = withLoading('unassign_addresses', async (address_id) => {
  await useApi.requestV2('delete', `v1/projects/${route.params.projectId}/addresses/${address_id}`)
  await getDetails()
})

function navigateToAddress(payload) {
  router.push({
    name: 'project-address',
    params: { objectId: payload.id },
  })
}

function setSelectedUnassignedAddresses(payload) {
  selectedUnassignedAddresses.value = payload
}
</script>
