<template>
  <div class="bg-white border rounded shadow-md shadow-gray-200 py-2 px-4 h-full">
    <!-- Only the panel matching the currentStepId will be displayed -->
    <div v-for="panel in panels" :key="panel.step_id" v-show="panel.step_id === currentStepId">
      <slot :name="panel.step_id"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    panels: {
      type: Array,
      required: true,
    },
    currentStepId: {
      type: [String, Number],
      default: null,
    },
  },
}
</script>
