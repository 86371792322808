import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import { useAuth0 } from '@auth0/auth0-vue'

const attachments = ref([])
const loadHandler = loadingHandler()
const inputDisabled = ref(false)

export default () => {
  const auth = useAuth0()
  const useApi = apiHandler()

  const organisationDetailFields = [
    {
      label: 'Bijlage',
      key: 'attachment',
      type: 'file',
      accept: '.docx',
    },
  ]

  const attachmentForm = formHandler()
  attachmentForm.create({ type: 'add', fields: organisationDetailFields, data: {} })

  async function list(payload) {
    loadHandler.setLoadingState('list', true)
    try {
      const attachable_id = payload.attachable_id
      const response = await useApi.requestV2(
        'get',
        `v1/attachments?attachable_id=${attachable_id}&attachable_type=address`
      )

      attachments.value = response.data
    } finally {
      loadHandler.setLoadingState('list', false)
    }
  }

  function uploadAttachment(file, attachable_id, attachable_type) {
    loadHandler.setLoadingState('upload_attachment', true)
    let payload = new FormData()
    payload.append('file', file)
    payload.append('auth0_user_id', auth.user.value.sub)
    payload.append('username', auth.user.value.name)
    payload.append('attachable_id', attachable_id)
    payload.append('attachable_type', attachable_type)

    useApi
      .requestV2('post', `v1/attachments`, payload)
      .then(() => {
        loadHandler.setLoadingState('upload_attachment', false)
        const refreshPayload = {
          attachable_id: attachable_id,
          attachable_type: attachable_type,
        }
        list(refreshPayload)
      })
      .catch((error) => {
        console.error('Failed to upload attachment', error)
        loadHandler.setLoadingState('upload_attachment', false)
      })
  }

  function downloadAttachment(attachmentId) {
    loadHandler.setLoadingState('download_attachment', true)

    useApi
      .requestV2('download', `v1/attachments/${attachmentId}`)
      .then((result) => {
        const filename = result.headers['x-filename']
        if (filename && result.data instanceof Blob) {
          // Check if data is a Blob
          const tempLink = document.createElement('a')
          tempLink.download = filename

          try {
            const objectUrl = URL.createObjectURL(result.data)
            tempLink.href = objectUrl

            document.body.appendChild(tempLink)
            tempLink.click()

            document.body.removeChild(tempLink)
            URL.revokeObjectURL(objectUrl)
          } catch (error) {
            console.error('Failed to create download link:', error)
          }
        } else {
          console.error('Filename header is missing or data is not a Blob:', result.data)
        }
      })
      .catch((error) => {
        console.error('Download error:', error)
      })
      .finally(() => {
        loadHandler.setLoadingState('download_attachment', false)
      })
  }

  async function deleteAttachment(attachment, attachableId, attachableType) {
    loadHandler.setLoadingState('delete', true)

    try {
      await useApi.requestV2('delete', `v1/attachments/${attachment.id}`)

      const refreshPayload = {
        attachable_id: attachableId,
        attachable_type: attachableType,
      }

      list(refreshPayload)
    } catch (error) {
      console.error('Failed to delete attachment:', error)
    } finally {
      loadHandler.setLoadingState('delete', false)
    }
  }

  return {
    attachments,
    list,
    uploadAttachment,
    downloadAttachment,
    inputDisabled,
    loadHandler,
    organisationDetailFields,
    attachmentForm,
    deleteAttachment,
  }
}
