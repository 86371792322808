<template>
  <div :id="id" style="height: 400px"></div>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'

export default {
  props: {
    handler: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      props.handler.initMap()
    })

    onUnmounted(() => {
      props.handler.cleanupMap()
    })
  },
}
</script>
