<template>
  <div>
    <div v-if="handler.tree[0]?.container" class="mb-2">
      <div class="flex items-center text-gray-500">
        <input
          type="checkbox"
          :checked="allChecked"
          :indeterminate="isIndeterminate"
          @change="toggleAll"
          class="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
        <div
          @click="handleToggleVisibility(handler.tree[0])"
          class="flex flex-row items-center align-middle cursor-pointer"
        >
          <span class="text-lg font-bold">{{ handler.tree[0].label }}</span>
          <svg
            v-if="handler.tree[0].children && handler.tree[0].children.length"
            :class="{ 'rotate-90': !handler.tree[0].collapsed }"
            class="fill-current text-gray-500 ml-2 transition-transform transform"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width="20"
            height="20"
          >
            <path d="M6 6L14 10L6 14V6Z" />
          </svg>
        </div>
      </div>
      <div v-if="!handler.tree[0].collapsed" class="pl-4 mt-2">
        <baseTreeNode
          v-for="node in handler.tree[0].children"
          :key="node.id"
          :node="node"
          @updateNode="handleUpdateNode"
          @toggleVisibility="handleToggleVisibility"
        />
      </div>
    </div>
    <div v-else>
      <baseTreeNode
        v-for="node in handler.tree"
        :key="node.id"
        :node="node"
        @updateNode="handleUpdateNode"
        @toggleVisibility="handleToggleVisibility"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    handler: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Function to get all checkable nodes (non-container nodes)
    const getAllCheckableNodes = (tree) => {
      const nodes = []
      const traverse = (node) => {
        if (!node.container) {
          nodes.push(node)
        }
        if (node.children) {
          node.children.forEach(traverse)
        }
      }
      tree.forEach(traverse)
      return nodes
    }

    // Computed property to check if all nodes are checked
    const allChecked = computed(() => {
      const checkableNodes = getAllCheckableNodes(props.handler.tree)
      return checkableNodes.length > 0 && checkableNodes.every((node) => node.checked)
    })

    // Computed property to check if some but not all nodes are checked
    const isIndeterminate = computed(() => {
      const checkableNodes = getAllCheckableNodes(props.handler.tree)
      const checkedCount = checkableNodes.filter((node) => node.checked).length
      return checkedCount > 0 && checkedCount < checkableNodes.length
    })

    // Function to toggle all checkboxes
    const toggleAll = () => {
      const checkableNodes = getAllCheckableNodes(props.handler.tree)
      const newValue = !allChecked.value
      checkableNodes.forEach((node) => {
        props.handler.updateNodeByPath(node.path, { checked: newValue })
      })
    }

    const handleUpdateNode = (node) => {
      const payload = {
        checked: !node.checked,
      }
      props.handler.updateNodeByPath(node.path, payload)
    }

    const handleToggleVisibility = (node) => {
      const payload = {
        collapsed: !node.collapsed,
      }
      props.handler.updateNodeByPath(node.path, payload)
    }

    return {
      handleUpdateNode,
      handleToggleVisibility,
      toggleAll,
      allChecked,
      isIndeterminate,
    }
  },
}
</script>
