/* eslint-disable no-unused-vars */
import { ref, computed, watch } from 'vue'
import useValidate from '@/use/validationHandler'
import dataChangeHandler from '@/use/dataChangeHandler'

export default () => {
  // data
  let fields = ref([])
  let data = ref({})
  let type = ref()
  let loading = ref(false)
  let errors = ref([])

  // handlers
  const validator = useValidate()
  const dataMonitor = dataChangeHandler()

  function create(options) {
    fields.value = options.fields
    type.value = options.type

    if (type.value === 'edit') {
      // if (options.data) {
      let payload = {}
      if (options.data) {
        payload = options.data
      }
      loadEditData(payload)
      dataMonitor.sync(data.value)
      // }
    } else {
      if (Object.keys(options).includes('data')) {
        data.value = JSON.parse(JSON.stringify(options.data))
      }

      loadAddData()
    }

    update_errors()
  }

  function setValue(key, value) {
    data.value[key] = value
  }

  const filteredFields = computed(() => {
    return fields.value.filter((field) => {
      if (field[type.value] === false) {
        return false
      }
      return true
    })
  })

  function loadAddData() {
    filteredFields.value.forEach((field) => {
      const key = field.key
      const type = field.type

      // check if there is prefill, neccesary for duplication
      if (!(key in data.value)) {
        // check if there is a manual default
        if ('default' in field) {
          data.value[key] = field.default
        } else {
          // else fill with type default
          if (type === 'list') {
            data.value[key] = []
          } else {
            data.value[key] = ''
          }
        }
      } else {
        // else pass and leave the prefill?
      }
    })
  }

  function loadEditData(payload) {
    fields.value.forEach((field) => {
      if (field.type !== 'subtitle') {
        const key = field.key

        // if there is a value to edit
        if (key in payload) {
          const value = payload[field.key]
          data.value[key] = value
        } else {
          // check if there is a manual default
          if ('default' in field) {
            data.value[key] = field.default
          } else {
            // else fill with type default
            if (field.type === 'list') {
              data.value[key] = []
            } else {
              data.value[key] = ''
            }
          }
        }
      }
    })

    // also add id
    data.value['id'] = payload['id']
  }

  function close() {
    data.value = {}
    fields.value = []
    type.value = undefined
  }

  function validate(event, type, rules, key) {
    const input = event.target.value

    // validatorguard
    if (validator.validate(input, type)) {
      // if input is file
      if (type === 'file') {
        data.value[key] = event.target.files[0]
      } else if (type === 'check') {
        data.value[key] = event.target.checked
      } else {
        data.value[key] = input
      }

      // update errors
      update_errors()
    } else {
      if (data.value[key] != undefined) {
        event.target.value = data.value[key]
      } else {
        event.target.value = null
      }
    }
  }

  function sync(payload) {
    data.value = payload
    update_errors()
  }

  function removeData(key) {
    data.value[key] = ''
    update_errors()
  }

  function addListItem(key, payload) {
    if (key in data.value) {
      data.value[key].push(payload)
    } else {
      data.value[key] = [payload]
    }
  }

  function removeListItem(key, index) {
    data.value[key].splice(index, 1)
  }

  function editListItem(key, index, value) {
    data.value[key][index] = value
  }

  function duplicateListItem(key, index) {
    const payload = JSON.parse(JSON.stringify(data.value[key][index]))
    data.value[key].splice(index, 0, payload)
  }

  function update_errors() {
    let error_list = []
    filteredFields.value.forEach((field) => {
      if (field.required) {
        const fieldValue = data.value[field.key]

        const dependent_show_field_key = field.dependent_show_field_key
        const dependent_show_field_type = field.dependent_show_field_type
        const dependent_show_values = field.dependent_show_values

        // if field is dependent
        if (dependent_show_field_key) {
          const dependent_field_value = data.value[dependent_show_field_key]

          // check if dependancy is active
          if (dependent_show_values.includes(dependent_field_value)) {
            if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
              // pass
            } else {
              error_list.push({
                message: `${field.label} is niet ingevuld`,
              })
            }
          } else {
            // pass if dependancy is not acive
          }
        } else {
          if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
            // pass
          } else {
            error_list.push({
              message: `${field.label} is niet ingevuld`,
            })
          }
        }
      }
    })
    errors.value = error_list
  }

  watch(
    data,
    (newData) => {
      fields.value.forEach((field) => {
        const isDependentActive =
          field.dependent_show_values && field.dependent_show_values.includes(newData[field.dependent_show_field_key])
        if ('executer' in field && typeof field.executer === 'function' && isDependentActive) {
          data.value[field.key] = field.executer(newData)
        }
      })
    },
    { deep: true }
  )

  return {
    fields,
    open,
    filteredFields,
    type,
    loadAddData,
    data,
    close,
    validate,
    setValue,
    loading,
    loadEditData,
    create,
    addListItem,
    removeListItem,
    sync,
    editListItem,
    duplicateListItem,
    errors,
    update_errors,
    removeData,
    dataMonitor,
  }
}
