import { ref } from 'vue'

let toasts = ref([])

function addToast(message, type, duration = 3000) {
  const id = Math.floor(Math.random() * 10000)
  toasts.value.push({ id, message, type })
  setTimeout(() => removeToast(id), duration)
}

function removeToast(id) {
  toasts.value = toasts.value.filter((t) => t.id !== id)
}

export default function useToast() {
  return { toasts, addToast, removeToast }
}
