<template>
  <div class="flex flex-wrap md:items-center mb-2 w-full">
    <div class="md:w-1/3">
      <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-2 py-2 text-sm">
        {{ label }}
      </label>
    </div>
    <div class="md:w-2/3 flex flex-row">
      <input
        :value="dateFromEpochDay(modelValue)"
        v-bind="$attrs"
        disabled="true"
        class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
        @input="handleInput($event)"
      />
      <base-button-drop-down
        class="ml-2"
        variant="primary"
        :handler="selectorDropDown"
        :display-text="''"
        :translate="false"
        icon="CalendarIcon"
        size="small"
        :alignRight="true"
      >
        <div class="flex flex-row p-2">
          <!-- month -->
          <base-button-drop-down
            class="w-48"
            :handler="monthDropDown"
            :displayText="usebaseDateInputHandler.monthNames[usebaseDateInputHandler.selectedMonth.value]"
            variant="neutral"
            :alignRight="true"
          >
            <base-button-drop-down-item
              class="w-full"
              v-for="month in usebaseDateInputHandler.months.value"
              :key="month.number"
              @click.stop="selectMonth(month.number - 1)"
            >
              {{ $translate(month.name) }}
            </base-button-drop-down-item></base-button-drop-down
          >

          <!-- year -->
          <base-button-drop-down
            class=""
            :handler="yearDropDown"
            :display-text="String(usebaseDateInputHandler.selectedYear.value)"
            variant="neutral"
            :translate="false"
          >
            <base-button-drop-down-item
              v-for="year in usebaseDateInputHandler.years.value"
              :key="year"
              @click.stop="selectYear(year)"
            >
              {{ year }}
            </base-button-drop-down-item>
          </base-button-drop-down>
        </div>

        <div class="grid grid-cols-7 gap-1 text-xs text-gray-300 p-2">
          <div v-for="day in usebaseDateInputHandler.weekdays" :key="day" class="text-center p-2">{{ day }}</div>
        </div>
        <div class="grid grid-cols-7 gap-1 bg-white p-2">
          <!-- offset cells for day alignment -->
          <div
            v-for="n in usebaseDateInputHandler.dayOffset()"
            :key="`empty-${n}`"
            class="text-center rounded !p-2"
          ></div>

          <!-- Adjusts gap between grid cells -->
          <base-button-drop-down-item
            v-for="day in usebaseDateInputHandler.days.value"
            :key="day.number"
            class="text-center rounded !p-2"
            :class="{
              'font-bold': usebaseDateInputHandler.isWeekend(day.date),
              'border rounded': usebaseDateInputHandler.isToday(day.date),
              'bg-sky-700 text-white': usebaseDateInputHandler.isSelected(day.date),
            }"
            @click="selectDay(day.number)"
          >
            {{ day.number }}
          </base-button-drop-down-item>
        </div>
        <div class="flex flex-row-reverse pb-4 pr-4">
          <baseButton @action="add()" size="small">Kies</baseButton>
        </div>
      </base-button-drop-down>
    </div>
  </div>
</template>

<script setup>
import useValidate from '@/use/validationHandler'
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'
import baseDateInputHandler from '@/plugins/base/components/form/date/baseDateInputHandler'
import filterHandler from '@/use/filterHandler'

const props = defineProps(['modelValue', 'label'])
const emit = defineEmits(['update:modelValue'])

const { validate } = useValidate()
const { dateFromEpochDay } = filterHandler()

const selectorDropDown = baseButtonDropDownHandler()
const monthDropDown = baseButtonDropDownHandler()
const yearDropDown = baseButtonDropDownHandler()
const usebaseDateInputHandler = baseDateInputHandler()

const handleInput = (event) => {
  if (validate(event.target.value, props.type)) {
    emit('update:modelValue', event.target.value)
  } else {
    event.target.value = props.modelValue
  }
}

function add() {
  emit('update:modelValue', usebaseDateInputHandler.getResult())
}

function selectDay(day) {
  usebaseDateInputHandler.selectedDay.value = day
}

function selectMonth(month) {
  usebaseDateInputHandler.selectedMonth.value = month
  monthDropDown.open.value = false
}

const selectYear = (year) => {
  usebaseDateInputHandler.selectedYear.value = year
  yearDropDown.open.value = false
}
</script>
