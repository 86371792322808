<template>
  <baseTable
    :fields="tableFields"
    :records="organisations"
    :records-per-page="25"
    :add-button="true"
    @add="showAddModal = true"
    :clickAble="true"
    :clickCallback="organisationClick"
  />
  <baseModal v-if="showAddModal" size="m" @close="closeModal" title="Add organisation">
    <form class="w-full flex flex-col">
      <baseInput v-model="name" label="Name" type="string" id="name" />
      <baseCheckBox v-model="procesTool" label="Proces Tool" id="procesTool" />
    </form>
    <template v-slot:buttons>
      <baseButton @action="addOrganisation" class="mr-2">Add</baseButton>
    </template>
  </baseModal>

  <baseModal v-if="showUsersModal" size="l" @close="closeUserModal" title="Users">
    <baseTable
      :fields="userFields"
      :records="users"
      :records-per-page="25"
      :add-button="true"
      :edit-button="false"
      :delete-button="false"
      :duplicate-button="false"
      :export-button="false"
      :clickAble="false"
      :sortable="true"
      @add="showAddUser"
    />
  </baseModal>

  <baseModal v-if="showAddUserModal" size="m" @close="closeAddUserModal" title="Add user">
    <form class="w-full flex flex-col">
      <baseTable
        :fields="userFields"
        :records="possibleUsers"
        :records-per-page="25"
        :add-button="false"
        :edit-button="false"
        :delete-button="false"
        :duplicate-button="false"
        :export-button="false"
        :clickAble="false"
        :sortable="true"
        :selectable="true"
        @selection="selectedUsers = $event"
      />
    </form>
    <template v-slot:buttons>
      <baseButton @action="addUser" class="mr-2">Add</baseButton>
    </template>
  </baseModal>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import apiHandler from '@/use/apiHandler'

const useApi = apiHandler()
const showAddModal = ref(false)
const showUsersModal = ref(false)
const showAddUserModal = ref(false)
const organisations = ref([])

const tableFields = [
  { key: 'name', label: 'Naam', type: 'string' },
  { key: 'auth0_org_id', label: 'Organisatie ID', type: 'string' },
  { key: 'user_count', label: 'Gebruikers', type: 'number' },
  { key: 'proces_tool', label: 'Proces Tool', type: 'boolean' },
]

const userFields = [
  { key: 'name', label: 'Naam', type: 'string' },
  { key: 'id', label: 'ID', type: 'string' },
]

const name = ref('')
const procesTool = ref(false)
const users = ref([])

const selectedOrganisation = ref(null)
const selectedUsers = ref([])
const allUsers = ref([])
const possibleUsers = ref([])

onMounted(async () => {
  try {
    const { data: orgData } = await useApi.requestV2('get', 'admin/organizations')
    organisations.value = orgData
    organisations.value.forEach((org) => {
      org.user_count = org.users.length
    })
  } catch (error) {
    console.error('Error fetching organisations:', error)
  }
  getUsers()
})

async function addOrganisation() {
  try {
    const response = await useApi.requestV2('post', 'admin/organizations/', {
      name: name.value,
      proces_tool: procesTool.value,
    })
    organisations.value.push(response.data)
    closeModal()
  } catch (error) {
    console.error('Error adding organisation:', error)
  }
}

async function getUsers() {
  try {
    const { data } = await useApi.requestV2('get', 'admin/users')
    allUsers.value = data
  } catch (error) {
    console.error('Error fetching users:', error)
  }
}

function closeModal() {
  name.value = ''
  procesTool.value = false
  showAddModal.value = false
}

function closeUserModal() {
  users.value = []
  showUsersModal.value = false
}

function organisationClick(organisation) {
  showUsersModal.value = true
  selectedOrganisation.value = organisation
  users.value = organisation.users
}

function closeAddUserModal() {
  selectedUsers.value = []
  showAddUserModal.value = false
}

function showAddUser() {
  showAddUserModal.value = true
  possibleUsers.value = allUsers.value.filter((user) => {
    return !selectedOrganisation.value.users.some((orgUser) => orgUser.id === user.id)
  })
}

function addUser() {
  const orgId = selectedOrganisation.value.id
  const userIds = selectedUsers.value.map((user) => user.id)
  useApi
    .requestV2('post', `admin/organizations/${orgId}/users`, { users: userIds })
    .then((response) => {
      users.value = response.data.users
      organisations.value = organisations.value.map((org) => {
        if (org.id === orgId) {
          org.users = org.users.concat(response.data.users)
          org.user_count = org.users.length
        }
        return org
      })
    })
    .finally(() => {
      closeAddUserModal()
    })
}
</script>
