<template>
  <baseDatePicker v-model="date" label="Datum" id="date" />
  <baseTimePicker v-model="time" label="Tijd" id="time" />
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const date = ref('')
const time = ref('')

onMounted(() => {
  const dateTime = new Date(props.modelValue)
  date.value = dateTime.toISOString().split('T')[0]
  time.value = dateTime.toISOString().split('T')[1].split('.')[0]
})

watch([date, time], () => {
  emit('update:modelValue', `${date.value}T${time.value}`)
})
</script>
