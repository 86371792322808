<template>
  <!-- selects -->
  <div class="relative w-full" tabindex="0">
    <div v-if="disabled">
      <div
        class="flex justify-between bg-gray-100 border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm"
      >
        <div>
          {{ options.find((option) => option.value === value)?.label }}
        </div>
        <div>
          <featherIcon class="w-4 h-4 my-auto mr-2" icon="ChevronDownIcon" />
        </div>
      </div>
    </div>
    <div
      v-else
      ref="selectRef"
      class="flex justify-between bg-white border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200 cursor-pointer"
      :class="required ? 'border-gray-200 border-l-sky-300 border-l-[6px]' : 'bg-white border-gray-200'"
      @click="toggleSelect()"
    >
      <div>
        {{ options.find((option) => option.value === value)?.label }}
      </div>
      <div>
        <featherIcon
          :class="`my-auto mr-2 h-4 w-4 ${rotatedStyle} transition duration-300 ease-in-out`"
          icon="ChevronDownIcon"
        />
      </div>
    </div>

    <transition name="expand" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
      <ul
        class="absolute bg-white w-full border-gray-200 text-gray-700 text-sm shadow-md overflow-auto transition-all duration-150"
        v-if="selectOpen"
      >
        <div class="p-2 mt-1 border">
          <li
            class="cursor-pointer hover:bg-gray-50 min-h-[20px]"
            v-for="option in options"
            :key="option.value"
            @click="handler.validate({ target: { value: option.value } }, 'select', [], dataKey, index)"
          >
            {{ option.label }}
          </li>
        </div>
      </ul>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue'
export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataKey: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorLevel: {
      type: String,
      default: 'info',
    },
  },
  setup() {
    const selectOpen = ref(false)
    const selectRef = ref(null)

    const rotatedStyle = computed(() => {
      return selectOpen.value ? 'rotate-180' : ''
    })

    const toggleSelect = () => {
      selectOpen.value = !selectOpen.value
    }

    function clicker(event) {
      if (selectRef.value) {
        if (!selectRef.value.contains(event.target)) {
          if (selectOpen.value) {
            toggleSelect()
          }
        }
      }
    }

    onMounted(() => {
      window.addEventListener('click', clicker)
    })

    onUnmounted(() => window.removeEventListener('click', clicker))

    return {
      selectOpen,
      selectRef,
      toggleSelect,
      rotatedStyle,
    }
  },
  // Animatie code
  methods: {
    beforeEnter(el) {
      el.style.maxHeight = '0'
      el.style.overflow = 'hidden'
    },
    enter(el, done) {
      el.style.maxHeight = el.scrollHeight + 'px'
      el.addEventListener('transitionend', () => {
        el.style.overflow = 'visible'
        done()
      })
    },
    beforeLeave(el) {
      el.style.maxHeight = el.scrollHeight + 'px'
      el.style.overflow = 'hidden'
    },
    leave(el, done) {
      el.style.maxHeight = '0'
      el.addEventListener('transitionend', () => {
        el.style.overflow = ''
        done()
      })
    },
  },
}
</script>
