<template>
  <div class="max-h-[70vh] overflow-y-auto">
    <baseButton @action="showModal = true"> Import </baseButton>

    <div v-if="addresses.length" class="mt-4">
      <ul>
        <div class="flex flex-row mb-2 items-center align-middle text-sm text-gray-500">
          <div class="w-full mr-2">Invoer</div>
          <div class="w-full">Adres</div>
          <div class="w-4"></div>
        </div>
        <li v-for="(address, index) in addresses" :key="index" class="mb-2">
          <div class="flex flex-row mb-2 items-center align-middle">
            <input
              class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 mr-2"
              v-model="addresses[index]"
              @input="updateAddress(index, $event.target.value)"
            />
            <input
              class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 mr-2"
              :value="geoLocators[index]?.searchResult[0]?.weergavenaam || ''"
              readonly
            />

            <baseButton @action="removeAddress(index)" size="s" variant="danger" class="my-auto">
              <featherIcon class="w-4 h-4 m-2" icon="TrashIcon" />
            </baseButton>
          </div>
        </li>
      </ul>
    </div>

    <baseModal v-if="showModal" @close="closeModal" :title="'Bulk import'" @enter="processAddresses" size="l">
      <textarea
        v-model="addressData"
        placeholder="Plak hier een lijst met addressen"
        rows="10"
        cols="50"
        class="w-full p-2 border border-gray-300 rounded mb-4"
      ></textarea>
      <template v-slot:buttons>
        <baseButton @action="processAddresses">{{ $translate('Import') }}</baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script>
import { ref } from 'vue'
import useGeoLocator from '@/use/useGeoLocator'
// import AddressSearch from '@/views/components/AddressSearch.vue'
import addressHandler from '@/use/addressHandler'

export default {
  components: {
    // AddressSearch,
  },
  props: {
    // handler: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  setup(props, { emit }) {
    const showModal = ref(false)
    const addressData = ref('')
    const addresses = ref([])
    const useAddress = addressHandler()

    const geoLocators = useAddress.geoLocatorsbulk

    const closeModal = () => {
      showModal.value = false
      addressData.value = ''
    }

    const processAddresses = () => {
      const rawAddresses = addressData.value
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line)
      const validAddresses = rawAddresses.filter((address) => validateAddress(address))
      const uniqueAddresses = [...new Set(validAddresses.filter((address) => !addresses.value.includes(address)))]
      addresses.value.push(...uniqueAddresses)

      uniqueAddresses.forEach((address) => {
        const geolocator = useGeoLocator()
        geolocator.setSearchInput(address)
        geoLocators.value.push(geolocator)
      })

      // Emit the valid addresses to the parent component or handle them as needed
      emit('addresses-imported', validAddresses)
      addressData.value = ''
      closeModal()
    }

    const validateAddress = (address) => {
      return address.length > 0
    }

    const updateAddress = (index, newAddress) => {
      addresses.value[index] = newAddress
      geoLocators.value[index].setSearchInput(newAddress)
    }

    const removeAddress = (index) => {
      addresses.value.splice(index, 1)
      geoLocators.value.splice(index, 1)
    }

    const handleLookupResult = (index, event) => {
      // Update the corresponding address in the addresses array with the lookup result
      addresses.value[index] = event.data.weergavenaam
    }

    return {
      showModal,
      addressData,
      addresses,
      geoLocators,
      closeModal,
      processAddresses,
      validateAddress,
      updateAddress,
      removeAddress,
      handleLookupResult,
    }
  },
}
</script>

<style scoped>
textarea {
  width: 100%;
  margin-bottom: 10px;
}
</style>
