<template>
  <div class="relative w-full select-none" tabindex="0">
    <div v-if="disabled">
      <div
        class="flex justify-between bg-gray-100 border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm"
      >
        <div>
          {{ options.find((option) => option.value === modelValue)?.label }}
        </div>
        <div>
          <featherIcon class="w-4 h-4 my-auto mr-2" icon="ChevronDownIcon" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex justify-between bg-white border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200 cursor-pointer"
      @click="toggleSelect()"
    >
      <div>
        {{ selected?.label }}
      </div>
      <div>
        <featherIcon
          :class="`my-auto mr-2 h-4 w-4 ${rotatedStyle} transition duration-300 ease-in-out`"
          icon="ChevronDownIcon"
        />
      </div>
    </div>
    <transition name="expand" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
      <ul
        class="absolute bg-white w-full border-gray-200 text-gray-700 text-sm shadow-md overflow-hidden transition-all duration-150"
        v-if="selectOpen"
      >
        <div class="py-2 px-4 mt-1 border">
          <li
            class="cursor-pointer hover:bg-gray-50 min-h-[20px]"
            v-for="option in options"
            :key="option.value"
            @click="selectOption(option.value)"
          >
            {{ option.label }}
          </li>
        </div>
      </ul>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
const selected = defineModel('selected')

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const selectOpen = ref(false)
const rotatedStyle = computed(() => (selectOpen.value ? 'transform rotate-180' : ''))

const toggleSelect = () => {
  selectOpen.value = !selectOpen.value
}

const selectOption = (value) => {
  selected.value = props.options.find((option) => option.value === value)
  selectOpen.value = false
}

// Transition methods
const beforeEnter = (el) => {
  el.style.height = '0'
}

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px'
}

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + 'px'
}

const leave = (el) => {
  el.style.height = '0'
}
</script>
