<template>
  <div class="z-30">
    <nav
      :class="surfaceColour"
      class="flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 text-lg h-14 text-gray-500"
    >
      <div class="flex flex-row h-full">
        <!-- logo -->
        <logoMain class="w-10 h-10 my-auto" />
        <!-- title -->
        <span class="ml-4 my-auto">{{ navBarConfig.title }}</span>
      </div>

      <!-- user menu -->
      <div ref="user_dropdown_ref">
        <div class="text-sm relative text-gray-500">
          <div class="flex flex-row">
            <div class="mr-4 text-right">
              <p>{{ userName }}</p>
              <p>{{ userEmail }}</p>
            </div>
            <div class="my-auto rounded-full bg-gray-200 cursor-pointer" @click="toggleDropDown()">
              <featherIcon class="w-5 h-5 m-2" icon="UserIcon" />
            </div>
          </div>

          <!-- dropper -->
          <div class="absolute bg-white w-full rounded shadow-md border border-gray-100" v-if="showDropDown">
            <!-- adnmin -->
            <div
              v-if="useContentGuard.canSee('admin:read')"
              class="hover:bg-gray-50 p-2 cursor-pointer"
              @click="clickAdmin()"
            >
              <span class="flex cursor-pointer">
                <featherIcon class="w-4 h-4 my-auto mr-2" icon="PieChartIcon" />
                <p class="my-auto">{{ $translate('Admin') }}</p>
              </span>
            </div>

            <!-- manual -->
            <div class="hover:bg-gray-50 p-2 cursor-pointer" @click="clickManual()">
              <span class="flex cursor-pointer">
                <featherIcon class="w-4 h-4 my-auto mr-2" icon="BookOpenIcon" />
                <p class="my-auto">{{ $translate('Manual') }}</p>
              </span>
            </div>

            <!-- logout -->
            <div class="hover:bg-gray-50 p-2 cursor-pointer" @click="logOut()">
              <span class="flex cursor-pointer">
                <featherIcon class="w-4 h-4 my-auto mr-2" icon="LogOutIcon" />
                <p class="my-auto">{{ $translate('Log out') }}</p>
              </span>
            </div>

            <div class="bg-gray-100 p-2 text-xs">
              <span class="flex">
                <p class="my-auto mx-auto text-center">{{ $translate('Version') }} {{ version }}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useAuth0 } from '@auth0/auth0-vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import { useRouter } from 'vue-router'
import logoMain from '@/components/extended/logoMain.vue'
import { version } from '../../package'
import calcualtorHandler2 from '@/use/calcualtorHandler2'

export default {
  setup() {
    const auth = useAuth0()
    const router = useRouter()
    const store = useStore()
    const showMenu = ref(false)
    const showDropDown = ref(false)
    const navBarConfig = computed(() => store.state.general.navBar)
    const surfaceColour = computed(() => store.getters['general/getStyle']('surface'))
    const onSurfaceColour = computed(() => store.getters['general/getStyle']('onsurface'))
    const useContentGuard = contentGuardHandler()
    const useCalculator = calcualtorHandler2()

    onMounted(() => {
      window.addEventListener('click', clicker)
    })

    function toggleMenu() {
      if (showMenu.value === false) {
        showMenu.value = true
      } else {
        showMenu.value = false
      }
    }

    function logOut() {
      auth.logout({
        logoutParams: { returnTo: window.location.origin },
      })
    }

    // dropdown
    const user_dropdown_ref = ref(null)

    onUnmounted(() => window.removeEventListener('click', clicker))

    function toggleDropDown() {
      showDropDown.value = !showDropDown.value
    }

    function clicker(event) {
      if (!user_dropdown_ref.value.contains(event.target)) {
        if (showDropDown.value) {
          toggleDropDown()
        }
      }
    }

    const userName = auth.user.value.name
    const userEmail = auth.user.value.email

    function clickManual() {
      toggleDropDown()
    }

    function clickAdmin() {
      router.push({ name: 'surveys_admin' })
      toggleDropDown()
    }

    function clickCalculator() {
      toggleDropDown()
      useCalculator.open()
    }

    return {
      showMenu,
      toggleMenu,
      navBarConfig,
      surfaceColour,
      onSurfaceColour,
      logOut,
      useContentGuard,
      userName,
      userEmail,
      showDropDown,
      user_dropdown_ref,
      toggleDropDown,
      clickManual,
      clickAdmin,
      version,
      clickCalculator,
    }
  },
  components: {
    logoMain,
  },
}
</script>
