<template>
  <div class="h-full min-h-0">
    <div class="h-full">
      <div class="flex h-full flex-col overflow-y-auto">
        <div>
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <basePageTitle :title="useContentGuard.organisation.value" subtitle="Profiel" />
            </div>
          </div>
        </div>
        <div class="h-full">
          <div class="flex flex-row gap-4 mb-2">
            <baseCard class="basis-1/3"> <baseKeyValue :data="data" :fields="fields"></baseKeyValue></baseCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import contentGuardHandler from '@/use/contentGuardHandler'
// import apiHandler from '@/use/apiHandler'

import { useAuth0 } from '@auth0/auth0-vue'

export default {
  setup() {
    const useContentGuard = contentGuardHandler()
    const auth = useAuth0()

    const fields = [
      {
        key: 'username',
        label: 'Gebruikersnaam',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'sub',
        label: 'Sub',
      },
      {
        key: 'updated_at',
        label: 'Aangepast op',
        filter: 'dateTimeFromTimestamp',
      },
    ]

    const data = computed(() => {
      return {
        username: auth.user.value.name,
        email: auth.user.value.email,
        sub: auth.user.value.sub,
        updated_at: auth.user.value.updated_at,
      }
    })

    return {
      useContentGuard,
      auth,
      fields,
      data,
    }
  },
}
</script>
