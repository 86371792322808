<template>
  <div class="min-h-16 rounded border border-gray-100 shadow-md shadow-gray-100">
    <div class="flex cursor-pointer flex-row items-start justify-between p-3" @click="toggle()">
      <!-- header -->
      <span class="flex w-full flex-row items-center justify-between">
        <slot name="header"></slot>
        <slot v-if="!open" name="header-right"></slot>
      </span>
      <span>
        <featherIcon
          :class="`my-auto mr-2 h-4 w-4 ${rotatedStyle} transition duration-300 ease-in-out`"
          icon="ChevronDownIcon"
        />
      </span>
    </div>
    <transition name="expand" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
      <div v-if="open" class="transition-all duration-300">
        <div class="p-3">
          <slot name="body"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
export default {
  props: {
    initial_open: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const open = ref(props.initial_open)

    function toggle() {
      open.value = !open.value
    }

    const rotatedStyle = computed(() => {
      return open.value ? 'rotate-180' : ''
    })

    return {
      open,
      toggle,
      rotatedStyle,
    }
  },
  // Animatie code
  methods: {
    beforeEnter(el) {
      el.style.maxHeight = '0'
      el.style.overflow = 'hidden'
    },
    enter(el, done) {
      el.style.maxHeight = el.scrollHeight + 'px'
      el.addEventListener('transitionend', () => {
        el.style.overflow = 'visible'
        el.style.maxHeight = ''
        done()
      })
    },
    beforeLeave(el) {
      el.style.maxHeight = el.scrollHeight + 'px'
      el.style.overflow = 'hidden'
    },
    leave(el, done) {
      el.style.maxHeight = '0'
      el.addEventListener('transitionend', () => {
        el.style.overflow = ''
        el.style.maxHeight = ''
        done()
      })
    },
  },
}
</script>
