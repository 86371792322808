import { ref } from 'vue'
import loadingHandler from '@/use/loadingHandler'
import L from 'leaflet'
import markerHandler from '@/views/components/mapIcons/elecricity'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

export default () => {
  const loadHandler = loadingHandler()
  const originalState = ref('')
  const geometry = ref({})
  const useMarkers = markerHandler()
  const settings = ref({})
  const searchMarker = ref({})

  function load(geometry, markerType) {
    geometry.value = geometry
    settings.value['markerType'] = markerType
  }

  function editGeometry() {
    loadHandler.setLoadingState('edit_geometry', true)
  }

  function setStateOriginal(state) {
    originalState.value = state
  }

  function getMarker(type) {
    const selector = {
      pinMarker: useMarkers.pinMarkers['default'],
    }

    return selector[type]
  }

  function panMap(map, lat, lng, zoom = null) {
    if (zoom !== null) {
      map.setView([lat, lng], zoom)
    } else {
      map.setView([lat, lng])
    }
  }

  function addSearchMarker(map, lat, lng) {
    map.removeLayer(searchMarker.value)
    searchMarker.value = L.marker([lat, lng], { icon: useMarkers.targetMarker })
    searchMarker.value.addTo(map)
  }

  function removeSearchMarker(map) {
    map.removeLayer(searchMarker.value)
  }

  function renderMap(target, name, clickable, settings) {
    const map = L.map(target)
    const nodes = settings.nodes
    var markers = L.markerClusterGroup()

    nodes.forEach((node) => {
      if (node.x && node.y) {
        const x = parseFloat(node.x)
        const y = parseFloat(node.y)
        const marker = L.marker([y, x], { icon: getMarker(settings.markerType) })
        markers.addLayer(marker)
      }
    })

    map.addLayer(markers)

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      maxZoom: 21,
      id: name,
    }).addTo(map)

    setTimeout(function () {
      map.invalidateSize()

      if (markers.getLayers().length > 0) {
        map.fitBounds(markers.getBounds().pad(0.1))
      } else {
        map.setView([52, 6], 7)
      }
    }, 100)

    return {
      map,
      markers,
    }
  }

  return {
    loadHandler,
    editGeometry,
    setStateOriginal,
    originalState,
    renderMap,
    load,
    geometry,
    panMap,
    addSearchMarker,
    removeSearchMarker,
  }
}
