import { ref, computed, nextTick } from 'vue'
import L from 'leaflet'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import markerHandler from '@/views/components/mapIcons/elecricity'
import { defaultMapConfig } from '@/plugins/base/components/map/defaultConfig'

export function useMap(customConfig = null) {
  const config = customConfig || defaultMapConfig

  const mapInstance = ref(null)
  const layers = ref({})
  const useMarkers = markerHandler()

  const initMap = () => {
    mapInstance.value = L.map(config.id, {
      center: config.defaultView.center,
      zoom: config.defaultView.zoom,
      maxZoom: config.basemap.maxZoom,
      attributionControl: false,
    })

    L.tileLayer(config.basemap.url, {
      attribution: config.basemap.attribution,
      maxZoom: config.basemap.maxZoom,
    }).addTo(mapInstance.value)

    config.layers.forEach(initLayer)
  }

  const initLayer = (layerConfig) => {
    let layer
    switch (layerConfig.type) {
      case 'point':
        layer = layerConfig.clustering ? L.markerClusterGroup() : L.layerGroup()
        break
      case 'polygon':
        layer = L.layerGroup()
        break
      // Add more layer types as needed
    }
    layers.value[layerConfig.id] = { layer, config: layerConfig }
    mapInstance.value.addLayer(layer)
  }

  const addFeatureToLayer = (layerId, feature) => {
    const { layer, config } = layers.value[layerId]
    let leafletFeature = null
    let latlng = null

    switch (config.type) {
      case 'point':
        latlng = L.latLng(feature.y, feature.x)
        leafletFeature = L.marker(latlng, { icon: getMarker(config.markerType) })
        break
      case 'polygon':
        leafletFeature = L.polygon(feature.coordinates, config.style)
        break
    }

    if (leafletFeature) {
      layer.addLayer(leafletFeature)
    }
  }

  const removeFeatureFromLayer = (layerId, featureId) => {
    const { layer } = layers.value[layerId]
    layer.eachLayer((feature) => {
      if (feature.feature && feature.feature.id === featureId) {
        layer.removeLayer(feature)
      }
    })
  }

  const updateLayerData = (layerId, data) => {
    return new Promise((resolve) => {
      const { layer } = layers.value[layerId]
      layer.clearLayers()
      data.forEach((feature) => addFeatureToLayer(layerId, feature))
      // Use nextTick or setTimeout to ensure the update has been processed
      nextTick(() => {
        resolve()
      })
    })
  }

  const getMarker = (type) => {
    const selector = {
      pinMarker: useMarkers.pinMarkers['default'],
      // Add other marker types here as needed
    }
    return selector[type] || selector.pinMarker
  }

  const panTo = (lat, lng, zoom = null) => {
    if (mapInstance.value) {
      mapInstance.value.setView([lat, lng], zoom)
    }
  }

  const zoomToLayer = (layerId) => {
    const { layer } = layers.value[layerId]
    const bounds = layer.getBounds()
    if (bounds.isValid()) {
      mapInstance.value.fitBounds(bounds)
    }
  }

  const zoomToFit = () => {
    const allBounds = Object.values(layers.value).map(({ layer }) => layer.getBounds())
    const validBounds = allBounds.filter((bounds) => bounds.isValid())
    if (validBounds.length > 0) {
      const combinedBounds = L.latLngBounds(validBounds)
      mapInstance.value.fitBounds(combinedBounds)
    }
  }

  const cleanupMap = () => {
    if (mapInstance.value) {
      mapInstance.value.remove()
    }
  }

  return {
    mapInstance,
    layers: computed(() => layers.value),
    initMap,
    addFeatureToLayer,
    removeFeatureFromLayer,
    updateLayerData,
    panTo,
    zoomToLayer,
    zoomToFit,
    cleanupMap,
  }
}
