<template>
  <div class="my-8">
    <basePageTitle title="Categorieën" class="my-8" />
  </div>

  <baseTable
    :fields="measureCategoryFields"
    :records="measureCategories"
    :records-per-page="25"
    :add-button="true"
    :edit-button="true"
    :delete-button="true"
    :clickAble="true"
    :loading="isLoading"
    @add="openAddMeasureCategory"
    @edit="openEditMeasureCategory"
    @delete="deleteMeasureCategory"
  />

  <baseModal v-if="showAddMeasureCategory" @close="closeAddMeasureCategory" :title="$translate('Add')">
    <baseForm :handler="measureCategoryForm" :loading="isLoadingAddMeasureCategory" />
    <template #buttons>
      <baseButton @action="addMeasureCategory" :loading="isLoadingAddMeasureCategory">
        {{ $translate('Add') }}
      </baseButton>
    </template>
  </baseModal>

  <baseModal v-if="showEditMeasureCategory" @close="closeEditMeasureCategory" :title="$translate('Edit')">
    <baseForm :handler="measureCategoryEditForm" :loading="isLoadingEditMeasureCategory" />
    <template #buttons>
      <baseButton @action="editMeasureCategory" :loading="isLoadingEditMeasureCategory">
        {{ $translate('Edit') }}
      </baseButton>
    </template>
  </baseModal>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import loadingHandler from '@/use/loadingHandler'
import apiHandler from '@/use/apiHandler'
import formHandler from '@/use/formHandler'
import { useRoute } from 'vue-router'

const route = useRoute()
const { states, somethingLoading, withLoading } = loadingHandler()
const useApi = apiHandler()

const project = ref({})
const measureCategories = ref([])
const showAddMeasureCategory = ref(false)
const showEditMeasureCategory = ref(false)
const editIndex = ref(null)

const measureCategoryForm = formHandler()
const measureCategoryEditForm = formHandler()

const isLoading = computed(() => somethingLoading.value)
const isLoadingAddMeasureCategory = computed(() => states.add_measure_category)
const isLoadingEditMeasureCategory = computed(() => states.edit_measure_category)

const measureCategoryFields = [
  {
    label: 'Omschrijving',
    key: 'omschrijving',
    type: 'string',
    edit: true,
  },
]

onMounted(async () => {
  getProjectDetails()
})

const getProjectDetails = withLoading('get_details', async () => {
  const id = route.params.projectId
  const [projectResponse, addressesResponse] = await Promise.all([
    useApi.requestV2('get', `v1/projects/${id}`),
    useApi.requestV2('get', `v1/projects/${id}/addresses`),
  ])

  project.value = { ...projectResponse.data, addresses: addressesResponse.data }

  if ('maatregelen_categorieen' in project.value.config) {
    measureCategories.value = project.value.config.maatregelen_categorieen
  }

  return project.value
})

function openAddMeasureCategory() {
  measureCategoryForm.create({
    type: 'add',
    fields: measureCategoryFields,
    data: {},
  })
  showAddMeasureCategory.value = true
}

function openEditMeasureCategory(record, index) {
  editIndex.value = index
  measureCategoryEditForm.create({
    type: 'edit',
    fields: measureCategoryFields,
    data: record,
  })
  showEditMeasureCategory.value = true
}

function closeAddMeasureCategory() {
  showAddMeasureCategory.value = false
}

function closeEditMeasureCategory() {
  editIndex.value = null
  showEditMeasureCategory.value = false
}

const addMeasureCategory = withLoading('add_measure_category', async () => {
  const newItem = measureCategoryForm.data.value
  const updatedConfig = [...measureCategories.value, newItem]

  project.value.config.maatregelen_categorieen = updatedConfig

  await useApi.requestV2('patch', `v1/projects/${project.value.id}`, {
    config: project.value.config,
  })

  measureCategories.value.push(newItem)
  showAddMeasureCategory.value = false
})

const deleteMeasureCategory = withLoading('delete_measure_category', async (_, index) => {
  const updatedConfig = [...measureCategories.value]
  updatedConfig.splice(index, 1)
  project.value.config.maatregelen_categorieen = updatedConfig

  await useApi.requestV2('patch', `v1/projects/${project.value.id}`, {
    config: project.value.config,
  })

  measureCategories.value.splice(index, 1)
})

const editMeasureCategory = withLoading('edit_measure_category', async () => {
  const index = editIndex.value
  const data = measureCategoryEditForm.data.value
  const updatedConfig = [...measureCategories.value]
  updatedConfig[index] = data
  project.value.config.maatregelen_categorieen = updatedConfig

  await useApi.requestV2('patch', `v1/projects/${project.value.id}`, {
    config: project.value.config,
  })

  measureCategories.value = updatedConfig
  closeEditMeasureCategory()
})
</script>
