import { computed, reactive } from 'vue'

export default () => {
  const states = reactive({})
  const somethingLoading = computed(() => Object.values(states).includes(true))

  function setLoadingState(processName, processStatus) {
    states[processName] = processStatus
  }

  function withLoading(key, asyncFunction) {
    return async (...args) => {
      setLoadingState(key, true)
      try {
        return await asyncFunction(...args)
      } finally {
        setLoadingState(key, false)
      }
    }
  }

  return {
    states,
    setLoadingState,
    somethingLoading,
    withLoading,
  }
}
