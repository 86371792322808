<template>
  <div class="flex flex-wrap md:items-center mb-2 w-full">
    <div class="md:w-1/3">
      <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-2 py-2 text-sm">
        {{ label }}
      </label>
    </div>
    <div class="md:w-2/3">
      <div class="relative w-full select-none" tabindex="0">
        <div v-if="disabled">
          <div
            class="flex justify-between bg-gray-100 border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm"
          >
            <div>
              {{ options.find((option) => option.value === model)?.label }}
            </div>
            <div>
              <featherIcon class="w-4 h-4 my-auto mr-2" icon="ChevronDownIcon" />
            </div>
          </div>
        </div>
        <div
          v-else
          ref="selectRef"
          class="flex justify-between bg-white border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200 cursor-pointer"
          @click="toggleSelect()"
        >
          <div>
            {{ options.find((option) => option.value === model)?.label }}
          </div>
          <div>
            <featherIcon
              :class="`my-auto mr-2 h-4 w-4 ${rotatedStyle} transition duration-300 ease-in-out`"
              icon="ChevronDownIcon"
            />
          </div>
        </div>
        <transition name="expand" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
          <ul
            class="absolute bg-white w-full border-gray-200 text-gray-700 text-sm shadow-md overflow-hidden transition-all duration-150"
            :class="zIndex"
            v-if="selectOpen"
          >
            <div class="py-2 px-4 mt-1 border">
              <li
                class="cursor-pointer hover:bg-gray-50 min-h-[20px]"
                v-for="option in options"
                :key="option.value"
                @click="selectOption(option.value)"
              >
                {{ option.label }}
              </li>
            </div>
          </ul>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { onMounted, onUnmounted } from 'vue'

const model = defineModel()
defineProps(['label', 'options', 'disabled', 'zIndex'])

const selectOpen = ref(false)
const rotatedStyle = computed(() => (selectOpen.value ? 'transform rotate-180' : ''))
const selectRef = ref(null)

onMounted(() => window.addEventListener('click', clicker))
onUnmounted(() => window.removeEventListener('click', clicker))

const clicker = (event) => {
  if (selectOpen.value && !selectRef.value.contains(event.target)) {
    selectOpen.value = false
  }
}

const toggleSelect = () => {
  selectOpen.value = !selectOpen.value
}

const selectOption = (value) => {
  // emit('update:modelValue', value)
  model.value = value
  selectOpen.value = false
}

// Transition methods
const beforeEnter = (el) => {
  el.style.height = '0'
}

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px'
}

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + 'px'
}

const leave = (el) => {
  el.style.height = '0'
}
</script>
