import backButton from './components/baseBackButton.vue'
import baseAttachments from './components/baseAttachments.vue'
import baseAppointments from './components/baseAppointments.vue'
import baseButton from './components/baseButton.vue'
import baseButtonDropDown from './components/baseButtonDropDown.vue'
import baseButtonDropDownItem from './components/baseButtonDropDownItem.vue'
import baseCalculator from './components/calculator/baseCalculator.vue'
import baseCalendarButton from './components/baseCalendarButton.vue'
import baseCard from './components/baseCard.vue'
import baseCollapse from './components/baseCollapse.vue'
import baseComments from './components/baseComments.vue'
import baseDateInput from './components/form/date/baseDateInput.vue'
import baseDateTime from './components/baseDateTime.vue'
import baseDatePicker from './components/baseDateInput.vue'
import baseField from './components/baseField.vue'
import baseForm from './components/baseForm.vue'
import baseFormCustom from './components/baseFormCustom.vue'
import baseGeolocator from './components/baseGeolocator.vue'
import baseInput from './components/baseInput.vue'
import baseKeyValue from './components/baseKeyValue.vue'
import baseList from './components/baseList.vue'
import baseListInput from './components/baseListInput.vue'
import baseLoader from './components/baseLoader.vue'
import baseModal from './components/baseModal.vue'
import baseMultiSelect from './components/baseMultiSelect.vue'
import basePageTitle from './components/basePageTitle.vue'
import basePanels from './components/basePanels.vue'
import baseTable from './components/baseTable.vue'
import baseTableSelect from './components/baseTableSelect.vue'
import baseTableSelectField from './components/baseTableSelectField.vue'
import baseTabs from './components/baseTabs.vue'
import baseTextBox from './components/baseTextBox.vue'
import baseTimePicker from './components/baseTimePicker.vue'
import baseToast from './components/baseToast.vue'
import baseTree from './components/baseTree.vue'
import baseTreeNode from './components/baseTreeNode.vue'
import baseSelect from './components/baseSelect.vue'
import baseSelectField from './components/form/baseSelectField.vue'
import baseSteps from './components/baseSteps.vue'
import featherIcon from './components/baseFeatherIcon.vue'
import stringField from './components/form/stringField.vue'
import baseCheckBox from './components/form/baseCheckBox.vue'
import baseMap from './components/map/baseMap.vue'
import baseProgressBar from './components/baseProgressBar.vue'

export default {
  backButton,
  baseAttachments,
  baseAppointments,
  baseButton,
  baseButtonDropDown,
  baseCalculator,
  baseCalendarButton,
  baseCard,
  baseCollapse,
  baseComments,
  baseDateInput,
  baseDateTime,
  baseDatePicker,
  baseField,
  baseForm,
  baseFormCustom,
  baseGeolocator,
  baseInput,
  baseKeyValue,
  baseList,
  baseListInput,
  baseLoader,
  baseModal,
  baseMultiSelect,
  basePageTitle,
  basePanels,
  baseTable,
  baseTableSelect,
  baseTableSelectField,
  baseTabs,
  baseTextBox,
  baseTimePicker,
  baseToast,
  baseTree,
  baseTreeNode,
  baseSelect,
  baseSelectField,
  baseSteps,
  featherIcon,
  stringField,
  baseCheckBox,
  baseButtonDropDownItem,
  baseMap,
  baseProgressBar,
}
