<template>
  <div class="my-8">
    <basePageTitle title="Data" class="my-8" />
  </div>
  <baseLoader class="text-slate-500" :loading="useExportData.loadHandler.states.get_records" />
  <baseTree :handler="treeAlgemeen" />
  <baseTree :handler="treeSoortWoning" />
  <baseTree :handler="treeInstallatiesHuidig" />
  <baseTree :handler="treeInstallatiesNieuw" />
  <baseTree :handler="treeResultsHuidig" />
  <baseTree :handler="treeResultsNieuw" />
  <baseTree :handler="treeResultsHuidig20" />
  <baseTree :handler="treeResultsNieuw20" />
  <baseTree :handler="treeMaatregelCategorieen" />
  <baseButton class="mt-2" size="small" @action="exportData">Exporteer</baseButton>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import tabHandler from '@/use/tabHandler'
import treeHandler from '@/use/treeHandler'
import exportProjectDataHandler from '@/use/exportProjectDataHandler'
import lookupHandler from '@/use/lookupHandler'

const props = defineProps({
  handler: {
    type: Object,
    required: true,
  },
})

const useTabs = tabHandler()
const useExportData = exportProjectDataHandler()
const useLookup = lookupHandler()
const recordsLoaded = ref(false)

const maatregelCategorieenCheckData = reactive([
  {
    id: 'maatregel_categorieen',
    label: 'Maatregelcategorieen',
    container: true,
    checked: false,
    collapsed: true,
    children: [],
  },
])

const treeAlgemeen = treeHandler(useLookup.algemeenCheckData)
const treeSoortWoning = treeHandler(useLookup.soortWoningCheckData)
const treeInstallatiesHuidig = treeHandler(useLookup.installatiesHuidigCheckData)
const treeInstallatiesNieuw = treeHandler(useLookup.installatiesNieuwCheckData)
const treeResultsHuidig20 = treeHandler(useLookup.resultsHuidig20CheckData)
const treeResultsNieuw20 = treeHandler(useLookup.resultsNieuw20CheckData)
const treeResultsHuidig = treeHandler(useLookup.resultsHuidigCheckData)
const treeResultsNieuw = treeHandler(useLookup.resultsNieuwCheckData)
const treeMaatregelCategorieen = treeHandler(maatregelCategorieenCheckData)

function loadMaatregelCategorieCheckData() {
  const maatregelCategorieen = props.handler.project.value?.config?.maatregelen_categorieen
  if (!maatregelCategorieen?.length) {
    return []
  }

  return maatregelCategorieen.map((item) => ({
    id: item.omschrijving,
    label: item.omschrijving,
    dataPath: ['maatregelen', item.omschrijving],
    checked: false,
    collapsed: true,
    children: [],
  }))
}

onMounted(() => {
  recordsLoaded.value = true
  const children = loadMaatregelCategorieCheckData()
  maatregelCategorieenCheckData[0].children = children
  // reattach paths
  treeMaatregelCategorieen.attachPaths(maatregelCategorieenCheckData)

  useTabs.create({
    tabs: [
      { id: 'overzicht', label: 'Overzicht', active: true },
      { id: 'maatregelen', label: 'Maatregelen', active: true },
      { id: 'steps_tasks', label: 'Stappen & Taken', active: true },
      { id: 'data', label: 'Data', active: true },
    ],
  })
})

function exportData() {
  const combinedPaths = {}
  const combinedLabels = {}
  const mergeCheckedPaths = (checkedPathsObject) => {
    Object.assign(combinedPaths, checkedPathsObject.paths)
    Object.assign(combinedLabels, checkedPathsObject.labels)
  }
  const trees = [
    treeAlgemeen,
    treeSoortWoning,
    treeInstallatiesHuidig,
    treeInstallatiesNieuw,
    treeResultsHuidig20,
    treeResultsNieuw20,
    treeResultsHuidig,
    treeResultsNieuw,
    treeMaatregelCategorieen,
  ]
  trees.forEach((tree) => mergeCheckedPaths(tree.checkedPaths.value))
  const treeData = { paths: combinedPaths, labels: combinedLabels }
  const projectData = props.handler.project
  const projectId = projectData.value.id
  useExportData.getProjectSurveys(projectId, treeData, projectData)
}
</script>
