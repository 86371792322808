<template>
  <div class="h-full min-h-0 overflow-y-auto">
    <div class="flex flex-col">
      <div class="flex justify-between items-center">
        <basePageTitle :title="useContentGuard.organisation.value" subtitle="Taken" />
      </div>
      <div class="flex flex-col gap-4 mt-4">
        <div v-for="project in projects" :key="project.id" class="w-full text-slate-700">
          <h2 class="text-2xl font-semibold text-center mb-4">{{ project.name }}</h2>
          <div class="pb-2 pr-1">
            <baseTable
              :fields="taskFields"
              :records="project.tasks"
              :records-per-page="10"
              actionField="Afgerond"
              @action="completeStep"
              actionValue="completed"
              :sortable="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import apiHandler from '@/use/apiHandler'

const useContentGuard = contentGuardHandler()
const useApi = apiHandler()

const projects = ref([])

const taskFields = [
  { label: 'Deadline', key: 'deadline', type: 'date' },
  { label: 'Taak', key: 'name', type: 'string' },
  { label: 'Adres', key: 'address_full', type: 'string' },
  { label: 'Omschrijving', key: 'description', type: 'string' },
  { label: 'Stap', key: 'step_name', type: 'string' },
]

onMounted(async () => {
  try {
    const { data: user } = await useApi.requestV2('get', 'v1/users/me')
    const { data: projectsData } = await useApi.requestV2('get', `v1/tasks/projects/users/${user.id}`)

    projects.value = projectsData.map((project) => ({
      ...project,
      tasks: project.steps.flatMap((step) =>
        step.tasks.map((task) => ({
          ...task,
          address_full: `${task.address?.address} ${task.address?.place}, ${task.address?.zip}`,
          step_name: step.name,
        }))
      ),
    }))
  } catch (error) {
    console.error('Error fetching data:', error)
  }
})

const completeStep = async (record, value) => {
  try {
    projects.value = projects.value.map((project) => ({
      ...project,
      tasks: project.tasks.map((task) => (task.id === record.id ? { ...task, completed: value } : task)),
      steps: project.steps.map((step) => ({
        ...step,
        finished: step.tasks.filter((task) => task.completed).length,
      })),
    }))
    await useApi.requestV2('patch', `v1/tasks/${record.id}`, { completed: value })
  } catch (error) {
    console.error('Error updating task:', error)
  }
}
</script>
