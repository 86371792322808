export const defaultMapConfig = {
  id: 'default-map',
  basemap: {
    url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
    maxZoom: 21,
  },
  defaultView: {
    center: [52, 6],
    zoom: 7,
  },
  layers: [
    {
      id: 'default-points',
      name: 'Default Points Layer',
      type: 'point',
      clustering: true,
      markerType: 'pinMarker',
      data: [],
    },
  ],
}
