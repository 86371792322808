<template>
  <vue-highcharts :options="chartOptions" />
</template>

<script setup>
import { computed } from 'vue'
import vueHighcharts from '@/use/highCharts'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  completedCount: {
    type: Number,
    required: true,
  },
  totalCount: {
    type: Number,
    required: true,
  },
  completedLabel: {
    type: String,
    default: 'Afgerond',
  },
  notCompletedLabel: {
    type: String,
    default: 'Niet afgerond',
  },
})

const chartOptions = computed(() => ({
  chart: {
    type: 'pie',
    custom: {},
    events: {
      render() {
        const chart = this,
          series = chart.series[0]
        let customLabel = chart.options.chart.custom.label

        if (!customLabel) {
          customLabel = chart.options.chart.custom.label = chart.renderer
            .label(`${props.title}: </br> ${props.completedCount} / ${props.totalCount}`)
            .css({
              color: '#000',
              textAnchor: 'middle',
            })
            .add()
        }

        const x = series.center[0] + chart.plotLeft,
          y = series.center[1] + chart.plotTop - customLabel.attr('height') / 2

        customLabel.attr({ x, y })
        customLabel.css({
          fontSize: `${series.center[2] / 15}px`,
        })
      },
    },
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  title: {
    text: '',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      allowPointSelect: true,
      cursor: 'pointer',
      borderRadius: 8,
      dataLabels: [
        {
          enabled: true,
          distance: 20,
          format: '{point.name}',
        },
        {
          enabled: true,
          distance: -15,
          format: '{point.percentage:.0f}%',
        },
      ],
      showInLegend: true,
    },
  },
  credits: {
    enabled: false,
  },
  colors: ['#0369a1', '#e5e7eb'],
  series: [
    {
      name: props.title,
      colorByPoint: true,
      innerSize: '75%',
      data: [
        { name: props.completedLabel, y: props.completedCount },
        { name: props.notCompletedLabel, y: props.totalCount - props.completedCount },
      ],
    },
  ],
}))
</script>
