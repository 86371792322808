<template>
  <component :is="iconComponent"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const iconComponent = defineAsyncComponent(() => import(`./icons/${props.icon}.vue`))
    return {
      iconComponent,
    }
  },
}
</script>
