<template>
  <div>
    <div
      class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
    >
      {{ itemDisplayTemplate(currentSelectedItems) }}
    </div>
    <div>
      <baseButton size="small" @click="showSelectModal = true">
        <featherIcon class="w-4 h-4" icon="UsersIcon" />
      </baseButton>
    </div>
  </div>

  <baseModal v-if="showSelectModal" @close="showSelectModal = false" title="Select">
    <baseTable
      :fields="fields"
      :records="items"
      :add-button="true"
      :search="true"
      :selectable="true"
      @add="onOpenAdd"
      @selection="currentSelectedItems = $event"
    />
    <template #buttons>
      <baseButton @action="select">Select</baseButton>
    </template>
  </baseModal>

  <baseModal v-if="showAddModal" @close="showAddModal = false" title="Add">
    <baseForm :handler="addForm" />
    <template #buttons>
      <baseButton @action="add">Add</baseButton>
    </template>
  </baseModal>
</template>

<script setup>
import { ref } from 'vue'
import formHandler from '@/use/formHandler'

const props = defineProps({
  fields: {
    type: Array,
    default: () => [],
  },
  items: {
    type: Array,
    default: () => [],
  },
  addFormFields: {
    type: Array,
    default: () => [],
  },
  itemDisplayTemplate: {
    type: Function,
    default: (item) => item,
  },
  addFunction: {
    type: Function,
    default: () => {},
  },
  selectFunction: {
    type: Function,
    default: () => {},
  },
  value: {
    type: Array,
    default: () => [],
  },
})

const showSelectModal = ref(false)
const showAddModal = ref(false)
const currentSelectedItems = ref([])
const selectedItems = ref([])

selectedItems.value = [...props.value]

const addForm = formHandler()
addForm.create({ type: 'add', fields: props.addFormFields, data: {} })

function select() {
  selectedItems.value = currentSelectedItems.value
  props.selectFunction(currentSelectedItems.value)
  showSelectModal.value = false
}

function onOpenAdd() {
  showAddModal.value = true
}

function add() {
  props.addFunction(addForm.data.value)
  showAddModal.value = false
}
</script>
