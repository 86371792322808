<template>
  <div class="flex flex-wrap md:items-center mb-2 w-full">
    <div class="md:w-1/3">
      <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-2 py-2 text-sm">
        {{ label }}
      </label>
    </div>
    <div class="md:w-2/3">
      <input
        :value="modelValue"
        v-bind="$attrs"
        class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
        :type="password ? 'password' : 'text'"
        @input="handleInput($event)"
      />
    </div>
  </div>
</template>

<script setup>
import useValidate from '@/use/validationHandler'

const props = defineProps(['modelValue', 'label', 'type', 'password'])
const emit = defineEmits(['update:modelValue'])

const { validate } = useValidate()

const handleInput = (event) => {
  if (validate(event.target.value, props.type)) {
    emit('update:modelValue', event.target.value)
  } else {
    event.target.value = props.modelValue
  }
}
</script>
