<template>
  <div>
    <baseCard>
      <div class="flex flex-row gap-4 text-sm">
        <div class="flex flex-col flex-none">
          <div v-for="(step, index) in steps" :key="step.name">
            <div
              class="flex flex-row align-middle items-center hover:bg-gray-50 p-2 mr-4 cursor-pointer rounded-lg"
              @click="selectedStep = index"
            >
              <div
                v-if="finishedSteps.includes(step.id)"
                :class="`${backGroundColor} ${textColor}`"
                class="flex justify-center items-center w-12 h-12 rounded-full opacity-50"
              >
                <featherIcon class="w-5 h-5" icon="CheckIcon" />
              </div>
              <div
                v-else-if="step.id === currentStep.id"
                class="border-2 border-sky-700 flex justify-center items-center w-12 h-12 rounded-full opacity-100"
              >
                <featherIcon class="w-5 h-5 text-sky-700" icon="BriefcaseIcon" />
              </div>
              <div
                v-else
                class="border-2 border-gray-300 flex justify-center items-center w-12 h-12 rounded-full opacity-100"
              >
                <featherIcon class="w-5 h-5 text-gray-300" icon="BriefcaseIcon" />
              </div>
              <div v-if="selectedStep === index" class="pl-4 text-gray-700 font-bold">
                {{ step.name }}
              </div>
              <div v-else class="pl-4 text-gray-500">{{ step.name }}</div>
            </div>
            <div v-if="index + 1 !== steps.length" class="w-16">
              <div
                v-if="finishedSteps.includes(step.id)"
                class="w-0.5 bg-sky-700 h-full mx-auto my-0 min-h-[24px] opacity-50"
              ></div>
              <div v-else class="w-0.5 bg-gray-300 h-full mx-auto my-0 min-h-[24px]"></div>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-initial text-gray-500">
          <basePageTitle :title="steps[selectedStep].name"> </basePageTitle>
          <div class="mt-4">{{ steps[selectedStep].description }}</div>
          <div class="mt-4">
            <baseButton
              v-if="steps[selectedStep].id === currentStep.id && selectedStep !== steps.length"
              size="small"
              @action="finishStep()"
            >
              Voltooien
            </baseButton>
            <baseButton
              v-else-if="finishedSteps[finishedSteps.length - 1] === steps[selectedStep].id"
              size="small"
              @action="handler.unfinishStep()"
            >
              Onvoltooien
            </baseButton>
          </div>
        </div>
      </div>
    </baseCard>
  </div>
</template>
<script>
import { ref, computed } from 'vue'
import themeHandler from '@/use/themeHandler'

export default {
  props: {
    steps: {
      type: Object,
    },
  },
  emits: ['complete'],
  setup(props, { emit }) {
    const useTheme = themeHandler()

    // Background and text color are not reactive, just constants based on theme
    const backGroundColor = useTheme.getBackgroundColor('primary')
    const textColor = useTheme.getTextColor('primary')

    const selectedStep = ref(0)

    const finishStep = () => {
      const currentStep = props.steps[selectedStep.value]
      emit('complete', currentStep.id)

      if (selectedStep.value < props.steps.length - 1) {
        selectedStep.value += 1
      }
    }

    // Computed properties to get current and finished steps
    const currentStep = computed(() => {
      return props.steps.find((step) => step.completed_at === null) || props.steps[props.steps.length - 1]
    })

    const finishedSteps = computed(() => {
      return props.steps.filter((step) => step.completed_at !== null).map((step) => step.id)
    })

    return {
      backGroundColor,
      textColor,
      finishStep,
      selectedStep,
      currentStep,
      finishedSteps,
    }
  },
}
</script>
