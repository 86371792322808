import { ref } from 'vue'

let show = ref(true)

export default () => {
  function toggle() {
    show.value = !show.value
  }

  return {
    show,
    toggle,
  }
}
