import { ref } from 'vue'

import vabiHandler from '@/use/vabiHandler.js'
import toastHandler from '@/use/toastHandler'
import formHandler from '@/use/formHandler'
import loadingHandler from '@/use/loadingHandler'

export default function useImportHandler() {
  const useVabi = vabiHandler()
  const useToast = toastHandler()
  const loadHandler = loadingHandler()

  const showImportXMLModal = ref(false)

  // Create a new form handler for XML file import
  const importXMLForm = formHandler()
  const importXMLFields = [
    {
      label: 'Huidige situatie XML',
      key: 'currentXML',
      type: 'file',
      accept: '.xml',
      required: false,
    },
    {
      label: 'Nieuwe situatie XML',
      key: 'newXML',
      type: 'file',
      accept: '.xml',
      required: false,
    },
    {
      label: 'Bron basisinformatie',
      key: 'basicInfoSource',
      type: 'select',
      options: [
        { value: 'current', label: 'Huidige situatie XML' },
        { value: 'new', label: 'Nieuwe situatie XML' },
      ],
      required: true,
    },
  ]

  const openImportXMLModal = () => {
    showImportXMLModal.value = true
    importXMLForm.create({ type: 'add', fields: importXMLFields, data: { basicInfoSource: 'current' } })
  }

  const closeImportXMLModal = () => {
    showImportXMLModal.value = false
  }

  const confirmImportXML = async () => {
    loadHandler.setLoadingState('import_record', true)
    try {
      const currentXML = importXMLForm.data.value.currentXML
        ? await readFileAsText(importXMLForm.data.value.currentXML)
        : null
      const newXML = importXMLForm.data.value.newXML ? await readFileAsText(importXMLForm.data.value.newXML) : null
      const basicInfoSource = importXMLForm.data.value.basicInfoSource

      if (!currentXML && !newXML) {
        throw new Error('At least one XML file must be selected')
      }

      let parsedFile
      if (currentXML && newXML) {
        parsedFile = useVabi.parseTwo(currentXML, newXML, basicInfoSource)
      } else if (currentXML) {
        parsedFile = useVabi.parse(currentXML, 'current')
      } else {
        parsedFile = useVabi.parse(newXML, 'new')
      }

      showImportXMLModal.value = false

      return parsedFile

      // useToast.addToast('XML file(s) imported successfully', 'success')
    } catch (error) {
      useToast.addToast(error.message || 'Error importing XML file(s)', 'danger')
      console.error(error)
    } finally {
      loadHandler.setLoadingState('import_record', false)
    }
  }

  const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => resolve(event.target.result)
      reader.onerror = (error) => reject(error)
      reader.readAsText(file)
    })
  }

  const openFilePicker = (type, callbackFunction) => {
    const fileTypeSelector = {
      woningtool: 'application/json',
      monitor: 'text/xml',
    }

    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = fileTypeSelector[type]

    const handleFileSelection = (event) => {
      const file = event.target.files[0]
      callbackFunction(file, type)
    }

    fileInput.addEventListener('change', handleFileSelection)
    fileInput.click()
  }

  return {
    openFilePicker,
    showImportXMLModal,
    importXMLForm,
    importXMLFields,
    openImportXMLModal,
    closeImportXMLModal,
    confirmImportXML,
    loadHandler,
  }
}
